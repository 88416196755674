import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CheckListRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.75 8.2481H19.25C19.6642 8.24981 20 7.914 20 7.49981C20 7.08556 19.6642 6.74981 19.25 6.74981H10.75C10.3358 6.74981 10 7.08556 10 7.49981C10 7.914 10.3358 8.24981 10.75 8.24981ZM5.50001 16.4998C4.94795 16.4998 4.50001 16.9477 4.50001 17.4998C4.50001 18.0519 4.94795 18.4998 5.50001 18.4998C6.05207 18.4998 6.50001 18.0519 6.50001 17.4998C6.50001 16.9477 6.05207 16.4998 5.50001 16.4998ZM19.25 11.7498H10.75C10.3358 11.7498 10 12.0856 10 12.4998C10 12.914 10.3358 13.2498 10.75 13.2498H19.25C19.6642 13.2498 20 12.914 20 12.4998C20 12.0856 19.6642 11.7498 19.25 11.7498ZM19.25 16.7498H9.75001C9.33582 16.7498 9.00001 17.0856 9.00001 17.4998C9.00001 17.914 9.33582 18.2498 9.75001 18.2498H19.25C19.6642 18.2498 20 17.914 20 17.4998C20 17.0856 19.6642 16.7498 19.25 16.7498ZM7.69238 5.74787L5.9717 7.66047L5.28029 6.96953C4.98732 6.67656 4.5127 6.67656 4.21973 6.96953C3.92676 7.2625 3.92676 7.73712 4.21973 8.03009L5.46973 9.28009C5.61035 9.42119 5.80176 9.49981 6.00001 9.49981C6.00685 9.49981 6.0127 9.49981 6.01954 9.49931C6.2256 9.49394 6.41991 9.40459 6.55763 9.25175L8.80763 6.75175C9.08498 6.44365 9.05957 5.96953 8.75195 5.69219C8.44435 5.41581 7.9707 5.44025 7.69238 5.74787ZM7.69238 10.7479L5.9717 12.6605L5.28029 11.9695C4.98732 11.6766 4.5127 11.6766 4.21973 11.9695C3.92676 12.2625 3.92676 12.7371 4.21973 13.0301L5.46973 14.2801C5.61035 14.4212 5.80176 14.4998 6.00001 14.4998C6.00685 14.4998 6.0127 14.4998 6.01954 14.4993C6.2256 14.4939 6.41991 14.4046 6.55763 14.2517L8.80763 11.7517C9.08498 11.4437 9.05957 10.9695 8.75195 10.6922C8.44435 10.4158 7.9707 10.4402 7.69238 10.7479Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CheckListRegular;