import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const GaugeRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.88889 10C8.39931 10 8 10.3993 8 10.8889C8 11.3785 8.39931 11.7778 8.88889 11.7778C9.37847 11.7778 9.77778 11.3785 9.77778 10.8889C9.77778 10.3993 9.37847 10 8.88889 10ZM7.55556 13.1111C7.06597 13.1111 6.66667 13.5104 6.66667 14C6.66667 14.4896 7.06597 14.8889 7.55556 14.8889C8.04514 14.8889 8.44444 14.4896 8.44444 14C8.44444 13.5104 8.04514 13.1111 7.55556 13.1111ZM15.5624 14C15.5624 14.4896 15.9617 14.8889 16.4513 14.8889C16.9409 14.8889 17.3402 14.4896 17.3402 14C17.3402 13.5104 16.9409 13.1111 16.4513 13.1111C15.9617 13.1111 15.5624 13.5104 15.5624 14ZM12 6C7.58333 6 4 9.58333 4 14C4 15.4653 4.39583 16.8403 5.08333 18.0208C5.23958 18.2882 5.53819 18.4444 5.84722 18.4444H18.1528C18.4618 18.4444 18.7604 18.2882 18.9167 18.0208C19.6042 16.8403 20 15.4653 20 14C20 9.58333 16.4167 6 12 6ZM12 17.1111C11.3874 17.1111 10.8889 16.6127 10.8889 16C10.8889 15.3873 11.3874 14.8889 12 14.8889C12.6127 14.8889 13.1111 15.3873 13.1111 16C13.1111 16.6127 12.6127 17.1111 12 17.1111ZM17.8958 17.1111H14.1657C14.3379 16.7764 14.4444 16.4024 14.4444 16C14.4444 15.3406 14.1806 14.7447 13.7562 14.305L15.8915 11.0308C16.0929 10.7227 16.0061 10.3095 15.6979 10.1081C15.388 9.90797 14.9757 9.99392 14.7752 10.3025L12.5996 13.6384C12.4067 13.5895 12.2081 13.5556 12 13.5556C10.65 13.5556 9.55556 14.6499 9.55556 16C9.55556 16.4024 9.66206 16.7764 9.83431 17.1111H6.10417C5.59722 16.1562 5.33333 15.0868 5.33333 14C5.33333 10.3229 8.32292 7.33333 12 7.33333C15.6771 7.33333 18.6667 10.3229 18.6667 14C18.6667 15.0868 18.4028 16.1562 17.8958 17.1111ZM12 8.66667C11.5104 8.66667 11.1111 9.06597 11.1111 9.55556C11.1111 10.0451 11.5104 10.4444 12 10.4444C12.4896 10.4444 12.8889 10.0451 12.8889 9.55556C12.8889 9.06597 12.4896 8.66667 12 8.66667Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default GaugeRegular;