import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CloseMarkRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.6733 16.4677C18.0237 16.8182 18.0237 17.3867 17.6733 17.7372C17.3229 18.0876 16.7543 18.0876 16.4039 17.7372L11.9844 13.2848L7.53349 17.7357C7.18307 18.0861 6.61451 18.0861 6.26405 17.7357C5.91359 17.3852 5.91363 16.8167 6.26405 16.4662L10.7164 12.0168L6.26282 7.53227C5.91239 7.18185 5.91239 6.61329 6.26282 6.26283C6.61324 5.91237 7.1818 5.91241 7.53226 6.26283L11.9844 10.7489L16.4353 6.29799C16.7857 5.94757 17.3543 5.94757 17.7047 6.29799C18.0552 6.64841 18.0552 7.21697 17.7047 7.56743L13.2523 12.0168L17.6733 16.4677Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CloseMarkRegular;