import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const LinkDashSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.1274 14.8723L18.9648 12.0349C20.3451 10.6546 20.3451 8.41745 18.9648 7.03748C18.2524 6.34499 17.3699 6 16.4649 6C15.5599 6 14.6559 6.34499 13.9659 7.03523L12.882 8.1192C13.2597 8.3195 13.6187 8.55869 13.9297 8.86969C14.0083 8.94834 14.0771 9.03263 14.1482 9.11563L15.0972 8.16665C15.4624 7.80121 15.9274 7.59997 16.4649 7.59997C17.0024 7.59997 17.4674 7.80114 17.8326 8.16645C18.1979 8.5317 18.3991 9.01743 18.3991 9.53417C18.3991 10.0509 18.1979 10.5367 17.8326 10.9019L14.8577 13.8768L14.1054 13.2871C14.6427 12.0051 14.4084 10.4772 13.3647 9.43468C12.6525 8.74494 11.77 8.39995 10.865 8.39995C10.125 8.39995 9.39179 8.64374 8.76931 9.10568L4.97039 6.12772C4.86039 6.0418 4.7299 6 4.6009 6C4.42278 6 4.24616 6.0789 4.12816 6.22967C3.92346 6.49066 3.96879 6.86791 4.22971 7.07215L19.0069 18.6494C19.2694 18.8545 19.6459 18.8076 19.8491 18.5475C20.0538 18.2865 20.0085 17.9092 19.7476 17.705L16.1274 14.8723ZM12.77 12.2399L10.1075 10.1549C10.345 10.0549 10.6 9.99991 10.865 9.99991C11.3818 9.99991 11.8675 10.2011 12.2327 10.5664C12.5982 10.9316 12.7992 11.4174 12.7992 11.9341C12.8 12.0374 12.785 12.1399 12.77 12.2399ZM9.85003 15.6848L8.90105 16.6338C8.53756 16.9773 8.05007 17.1998 7.53508 17.1998C7.01835 17.1998 6.53261 16.9986 6.16736 16.6333C5.80212 16.268 5.60088 15.7823 5.60088 15.2655C5.60088 14.7488 5.8021 14.2631 6.16736 13.8978L8.28807 11.7771L7.0176 10.7824L5.03539 12.7674C3.65517 14.1476 3.65517 16.3848 5.03539 17.7647C5.72537 18.4547 6.6301 18.7997 7.53508 18.7997C8.43956 18.7997 9.34404 18.4547 10.034 17.7645L11.118 16.6805C10.7403 16.4802 10.3813 16.241 10.0703 15.93C9.99003 15.8523 9.92253 15.7673 9.85003 15.6848ZM10.635 15.3648C11.325 16.0548 12.23 16.3998 13.135 16.3998C13.4345 16.3998 13.7309 16.3477 14.0227 16.272L9.61504 12.8174C9.59754 13.7373 9.93253 14.6623 10.635 15.3648Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default LinkDashSolid;