import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const EyeSlashRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_346_11185)">
          <path d="M19.7702 17.7275L17.1842 15.7007C17.9679 14.9022 18.6278 13.9305 19.1132 12.8256C19.1609 12.7149 19.2 12.5239 19.2 12.4C19.2 12.2756 19.1609 12.0851 19.1132 11.9743C17.757 8.88813 15.0734 6.8 12 6.8C10.4333 6.8 8.97302 7.35165 7.73357 8.29345L4.97035 6.12773C4.86017 6.0418 4.72972 6 4.6008 6C4.42267 6 4.24612 6.0789 4.12815 6.2297C3.92347 6.49062 3.96877 6.86792 4.22972 7.07222L19.0296 18.6721C19.2921 18.8772 19.6687 18.8303 19.8718 18.5701C20.0765 18.3092 20.0312 17.9318 19.7702 17.7275ZM13.8598 13.0951L11.8185 11.4952C11.9242 11.2831 12 11.0538 12 10.8C12 10.663 11.9781 10.5319 11.9454 10.4055C11.9639 10.405 11.9813 10.4 12 10.4C13.1028 10.4 14 11.2972 14 12.4014C14 12.6477 13.9419 12.8772 13.8598 13.0951ZM14.8301 13.8556C15.0596 13.4191 15.2 12.9297 15.2 12.4014V12.4C15.2 10.6337 13.7667 9.2 12 9.2C11.1948 9.2 10.4672 9.5043 9.90822 9.9979L8.70785 9.05713C9.70037 8.37583 10.8281 8 12 8C14.487 8 16.7791 9.68745 17.9894 12.3999C17.5379 13.4095 16.9291 14.2607 16.2242 14.9482L14.8301 13.8556ZM12.0014 15.6C12.3368 15.6 12.6539 15.5339 12.9578 15.4381L11.5708 14.351C10.9763 14.2202 10.4863 13.8221 10.2194 13.2918L8.82062 12.1954C8.8162 12.2644 8.79997 12.3299 8.79997 12.4C8.79997 14.1663 10.2333 15.6 12 15.6H12.0014ZM12 16.8C9.51302 16.8 7.22087 15.1125 6.01057 12.4001C6.28142 11.7945 6.61287 11.2494 6.98095 10.7536L6.03192 10.0098C5.58772 10.6033 5.20027 11.2607 4.8867 11.9743C4.83905 12.0851 4.79997 12.276 4.79997 12.4C4.79997 12.5244 4.83905 12.7149 4.8867 12.8256C6.24295 15.9118 8.92655 18 12 18C13.1364 18 14.2191 17.7128 15.2016 17.1967L14.1301 16.3569C13.4507 16.6358 12.7364 16.8 12 16.8Z" fill="currentColor"/>
        </g>
        <defs>
          <clipPath id="clip0_346_11185">
            <rect width="16" height="12.8" fill="white" transform="translate(4 6)"/>
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
}

export default EyeSlashRegular;