import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CloseMarkLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.8241 17.8241C17.5897 18.0586 17.2103 18.0586 16.9755 17.8241L12.0006 12.848L7.02446 17.8241C6.78998 18.0586 6.4106 18.0586 6.17586 17.8241C5.94138 17.5897 5.94138 17.2103 6.17586 16.9755L11.1531 12.0006L6.17661 7.02446C5.94213 6.78998 5.94213 6.4106 6.17661 6.17586C6.41109 5.94138 6.79047 5.94138 7.02521 6.17586L12.0006 11.1531L16.9767 6.17699C17.2111 5.9425 17.5905 5.9425 17.8253 6.17699C18.0597 6.41147 18.0597 6.79084 17.8253 7.02559L12.848 12.0006L17.8241 16.9767C18.0604 17.2092 18.0604 17.5916 17.8241 17.8241Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CloseMarkLight;