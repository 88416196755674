import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const LinkDashRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.29507 16.8773C8.82707 17.3448 8.21259 17.5788 7.59785 17.5788C6.98336 17.5788 6.36862 17.3448 5.90063 16.8773C4.96514 15.9413 4.96514 14.4188 5.90063 13.4824L7.91759 11.4657L6.96761 10.7424L5.05364 12.6561C3.65017 14.0601 3.65017 16.3436 5.05364 17.7485C6.45762 19.1518 8.74108 19.1518 10.1461 17.7485L11.565 16.3273C11.2053 16.1417 10.8673 15.9141 10.5653 15.6303L9.29507 16.8773ZM16.1774 14.9123L18.9474 12.1449C20.3509 10.7409 20.3509 8.45746 18.9474 7.05248C17.5434 5.64926 15.26 5.64926 13.855 7.05248L12.435 8.47346C12.7948 8.65905 13.1327 8.8867 13.4347 9.17044L14.7045 7.90047C15.1725 7.43297 15.787 7.19898 16.4017 7.19898C17.0162 7.19898 17.6309 7.43295 18.0989 7.90047C19.0344 8.83645 19.0344 10.3589 18.0989 11.2954L15.2715 14.1229C15.2567 14.1376 15.2393 14.1487 15.2242 14.163L13.9332 13.1513C13.9927 12.8991 14.0279 12.6396 14.0279 12.3733C14.0279 11.4116 13.6531 10.5073 12.9732 9.82836C12.2934 9.14837 11.3892 8.77362 10.4282 8.77362C9.86546 8.77362 9.32922 8.916 8.83922 9.15887L4.97039 6.12772C4.8604 6.0418 4.7299 6 4.6009 6C4.42278 6 4.24616 6.0789 4.12816 6.22967C3.92346 6.49067 3.96879 6.86791 4.22971 7.07216L19.0069 18.6494C19.2694 18.8545 19.6459 18.8076 19.8492 18.5475C20.0539 18.2865 20.0085 17.9093 19.7476 17.705L16.1774 14.9123ZM9.96755 10.0449C10.7303 9.9026 11.557 10.1105 12.1233 10.6767C12.5538 11.1072 12.793 11.6734 12.817 12.2781L9.96755 10.0449ZM11.0275 14.9723C11.7073 15.6523 12.6115 16.0271 13.5725 16.0271C13.6133 16.0271 13.6521 16.0167 13.6927 16.0154L10.0276 13.1599C10.1901 13.8399 10.5025 14.4673 11.0275 14.9723Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default LinkDashRegular;