import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const BuildingSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 4C17.3281 4 18 4.67156 18 5.5V18.5C18 19.3281 17.3281 20 16.5 20H13.5V17.5C13.5 16.6719 12.8281 16 12 16C11.1719 16 10.5 16.6719 10.5 17.5V20H7.5C6.67156 20 6 19.3281 6 18.5V5.5C6 4.67156 6.67156 4 7.5 4H16.5ZM8 12.5C8 12.775 8.22375 13 8.5 13H9.5C9.775 13 10 12.775 10 12.5V11.5C10 11.225 9.775 11 9.5 11H8.5C8.22375 11 8 11.225 8 11.5V12.5ZM11.5 11C11.225 11 11 11.225 11 11.5V12.5C11 12.775 11.225 13 11.5 13H12.5C12.775 13 13 12.775 13 12.5V11.5C13 11.225 12.775 11 12.5 11H11.5ZM14 12.5C14 12.775 14.225 13 14.5 13H15.5C15.775 13 16 12.775 16 12.5V11.5C16 11.225 15.775 11 15.5 11H14.5C14.225 11 14 11.225 14 11.5V12.5ZM8.5 7C8.22375 7 8 7.225 8 7.5V8.5C8 8.775 8.22375 9 8.5 9H9.5C9.775 9 10 8.775 10 8.5V7.5C10 7.225 9.775 7 9.5 7H8.5ZM11 8.5C11 8.775 11.225 9 11.5 9H12.5C12.775 9 13 8.775 13 8.5V7.5C13 7.225 12.775 7 12.5 7H11.5C11.225 7 11 7.225 11 7.5V8.5ZM14.5 7C14.225 7 14 7.225 14 7.5V8.5C14 8.775 14.225 9 14.5 9H15.5C15.775 9 16 8.775 16 8.5V7.5C16 7.225 15.775 7 15.5 7H14.5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default BuildingSolid;