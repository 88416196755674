import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const HamburguerLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.57143 7.14286H19.4286C19.7444 7.14286 20 6.88729 20 6.57143C20 6.25557 19.7444 6 19.4286 6H4.57143C4.25557 6 4 6.25557 4 6.57143C4 6.88729 4.25557 7.14286 4.57143 7.14286ZM19.4286 11.7143H4.57143C4.25557 11.7143 4 11.9699 4 12.2857C4 12.6016 4.25557 12.8571 4.57143 12.8571H19.4286C19.7444 12.8571 20 12.6016 20 12.2857C20 11.9699 19.7444 11.7143 19.4286 11.7143ZM19.4286 17.4286H4.57143C4.25557 17.4286 4 17.6841 4 18C4 18.3159 4.25557 18.5714 4.57143 18.5714H19.4286C19.7444 18.5714 20 18.3159 20 18C20 17.6841 19.7444 17.4286 19.4286 17.4286Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default HamburguerLight;