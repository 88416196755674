import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const HamburguerRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.1429 16.4286H4.85714C4.38571 16.4286 4 16.8143 4 17.2857C4 17.7571 4.38571 18.1429 4.85714 18.1429H19.1429C19.6143 18.1429 20 17.7571 20 17.2857C20 16.8143 19.6143 16.4286 19.1429 16.4286ZM19.1429 5H4.85714C4.38571 5 4 5.38571 4 5.85714C4 6.32857 4.38571 6.71429 4.85714 6.71429H19.1429C19.6143 6.71429 20 6.32857 20 5.85714C20 5.38571 19.6143 5 19.1429 5ZM19.1429 10.7143H4.85714C4.38571 10.7143 4 11.1 4 11.5714C4 12.0429 4.38571 12.4286 4.85714 12.4286H19.1429C19.6143 12.4286 20 12.0429 20 11.5714C20 11.1 19.6143 10.7143 19.1429 10.7143Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default HamburguerRegular;