import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const BuildingLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 7.25C8.5 6.83594 8.83594 6.5 9.25 6.5H10.75C11.1656 6.5 11.5 6.83594 11.5 7.25V8.75C11.5 9.16563 11.1656 9.5 10.75 9.5H9.25C8.83594 9.5 8.5 9.16563 8.5 8.75V7.25ZM9.5 7.5V8.5H10.5V7.5H9.5ZM14.75 6.5C15.1656 6.5 15.5 6.83594 15.5 7.25V8.75C15.5 9.16563 15.1656 9.5 14.75 9.5H13.25C12.8344 9.5 12.5 9.16563 12.5 8.75V7.25C12.5 6.83594 12.8344 6.5 13.25 6.5H14.75ZM14.5 8.5V7.5H13.5V8.5H14.5ZM8.5 11.25C8.5 10.8344 8.83594 10.5 9.25 10.5H10.75C11.1656 10.5 11.5 10.8344 11.5 11.25V12.75C11.5 13.1656 11.1656 13.5 10.75 13.5H9.25C8.83594 13.5 8.5 13.1656 8.5 12.75V11.25ZM9.5 11.5V12.5H10.5V11.5H9.5ZM14.75 10.5C15.1656 10.5 15.5 10.8344 15.5 11.25V12.75C15.5 13.1656 15.1656 13.5 14.75 13.5H13.25C12.8344 13.5 12.5 13.1656 12.5 12.75V11.25C12.5 10.8344 12.8344 10.5 13.25 10.5H14.75ZM14.5 12.5V11.5H13.5V12.5H14.5ZM7.5 20C6.67156 20 6 19.3281 6 18.5V5.5C6 4.67156 6.67156 4 7.5 4H16.5C17.3281 4 18 4.67156 18 5.5V18.5C18 19.3281 17.3281 20 16.5 20H7.5ZM7 5.5V18.5C7 18.775 7.22375 19 7.5 19H10V17.5C10 16.3969 10.8969 15.5 12 15.5C13.1031 15.5 14 16.3969 14 17.5V19H16.5C16.775 19 17 18.775 17 18.5V5.5C17 5.22375 16.775 5 16.5 5H7.5C7.22375 5 7 5.22375 7 5.5ZM13 19V17.5C13 16.9469 12.5531 16.5 12 16.5C11.4469 16.5 11 16.9469 11 17.5V19H13Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default BuildingLight;