import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ChevronLeftRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7655 20.022L7.95142 12.911C7.78591 12.7397 7.70372 12.5174 7.70372 12.2964C7.70372 12.0753 7.78591 11.8531 7.95142 11.6818L14.7655 4.57076C15.1045 4.21543 15.667 4.20383 16.0212 4.54413C16.3776 4.88209 16.3892 5.44691 16.0478 5.79991L9.8238 12.2964L16.0478 18.7929C16.3892 19.1447 16.3776 19.7107 16.0212 20.0487C15.667 20.3889 15.1045 20.3774 14.7655 20.022Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default ChevronLeftRegular;