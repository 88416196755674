import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const Logout: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
    return (
        <IconWrapper {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5556 16.1044L20 11.66M20 11.66L15.5556 7.21555M20 11.66H9.33333M12 16.1044C12 16.3672 12 16.4985 11.9902 16.6123C11.8887 17.7951 11.0176 18.7683 9.85336 18.9998C9.74136 19.0221 9.61068 19.0366 9.34965 19.0656L8.44173 19.1665C7.07776 19.318 6.39574 19.3938 5.85392 19.2204C5.1315 18.9892 4.5417 18.4613 4.23216 17.7689C4 17.2495 4 16.5633 4 15.1909V8.12906C4 6.75667 4 6.07048 4.23216 5.55112C4.5417 4.85864 5.1315 4.33075 5.85392 4.09957C6.39574 3.92619 7.07774 4.00197 8.44173 4.15352L9.34964 4.2544C9.61077 4.28341 9.74134 4.29792 9.85336 4.3202C11.0176 4.55169 11.8887 5.52492 11.9902 6.70765C12 6.82145 12 6.95281 12 7.21555" stroke="#3B454F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </IconWrapper>
    );
}

export default Logout;

