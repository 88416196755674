import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const TrashCanSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 18.5C6 19.3281 6.67188 20 7.5 20H16.5C17.3281 20 18 19.3281 18 18.5V8H6V18.5ZM14.5 10.5C14.5 10.2227 14.7227 10 15 10C15.2773 10 15.5 10.2227 15.5 10.5V17.5C15.5 17.7773 15.2773 18 15 18C14.7227 18 14.5 17.7773 14.5 17.5V10.5ZM11.5 10.5C11.5 10.2227 11.7227 10 12 10C12.2773 10 12.5 10.2227 12.5 10.5V17.5C12.5 17.7773 12.2773 18 12 18C11.7227 18 11.5 17.7773 11.5 17.5V10.5ZM8.5 10.5C8.5 10.2227 8.72266 10 9 10C9.27734 10 9.5 10.2227 9.5 10.5V17.5C9.5 17.7773 9.27734 18 9 18C8.72266 18 8.5 17.7773 8.5 17.5V10.5ZM18.5 5H15L14.6382 4.27637C14.5535 4.107 14.3804 4 14.191 4H9.80903C9.61963 4 9.44653 4.107 9.36181 4.27637L9 5H5.5C5.22387 5 5 5.22381 5 5.5V6.5C5 6.77612 5.22387 7 5.5 7H18.5C18.7762 7 19 6.77612 19 6.5V5.5C19 5.22381 18.7762 5 18.5 5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default TrashCanSolid;