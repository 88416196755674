import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const Locker: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
    return (
        <IconWrapper {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.4444 11.1111V8.44444C16.4444 5.98985 14.4546 4 12 4C9.5454 4 7.55556 5.98985 7.55556 8.44444V11.1111M8.26667 20H15.7333C17.2268 20 17.9735 20 18.544 19.7094C19.0457 19.4537 19.4537 19.0457 19.7094 18.544C20 17.9735 20 17.2268 20 15.7333V15.3778C20 13.8843 20 13.1376 19.7094 12.5671C19.4537 12.0654 19.0457 11.6574 18.544 11.4018C17.9735 11.1111 17.2268 11.1111 15.7333 11.1111H8.26667C6.77319 11.1111 6.02646 11.1111 5.45603 11.4018C4.95426 11.6574 4.54631 12.0654 4.29065 12.5671C4 13.1376 4 13.8843 4 15.3778V15.7333C4 17.2268 4 17.9735 4.29065 18.544C4.54631 19.0457 4.95426 19.4537 5.45603 19.7094C6.02646 20 6.77319 20 8.26667 20Z" stroke="#3B454F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </IconWrapper>
    );
}

export default Locker;

