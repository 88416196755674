import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const BarCodeLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M19.5 5C19.225 5 19 5.22375 19 5.5V18.5C19 18.7764 19.2236 19 19.5 19C19.7764 19 20 18.7764 20 18.5V5.5C20 5.22375 19.775 5 19.5 5ZM16.5 5C16.225 5 16 5.22375 16 5.5V18.5C16 18.7764 16.2236 19 16.5 19C16.7764 19 17 18.7764 17 18.5V5.5C17 5.22375 16.775 5 16.5 5ZM13.5 5C13.225 5 13 5.22375 13 5.5V18.5C13 18.7764 13.2236 19 13.5 19C13.7764 19 14 18.7764 14 18.5V5.5C14 5.22375 13.775 5 13.5 5ZM11.5 5C11.225 5 11 5.22375 11 5.5V18.5C11 18.775 11.225 19 11.5 19C11.775 19 12 18.7764 12 18.5V5.5C12 5.22375 11.775 5 11.5 5ZM8.5 5C8.225 5 7.97187 5.22375 7.97187 5.47187V18.4719C7.97187 18.775 8.225 19 8.5 19C8.775 19 9 18.775 9 18.5V5.5C9 5.22375 8.775 5 8.5 5ZM6.5 5C6.22375 5 6 5.22375 6 5.5V18.5C6 18.775 6.22375 19 6.5 19C6.77625 19 6.97187 18.7764 6.97187 18.5281V5.47187C6.97187 5.22375 6.77625 5 6.5 5ZM4.5 5C4.22363 5 4 5.22375 4 5.5V18.5C4 18.775 4.22363 19 4.5 19C4.77637 19 5 18.775 5 18.5V5.5C5 5.22375 4.77625 5 4.5 5Z" fill="#3B454F"/>
      </svg>
    </IconWrapper>
  );
}

export default BarCodeLight;