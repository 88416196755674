import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const BarCodeRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M7.47187 5C7.19691 5 7 5.22503 7 5.47187V18.4719C6.97187 18.775 7.225 19 7.47187 19C7.74684 19 7.97187 18.775 7.97187 18.5281V5.47187C7.97187 5.225 7.775 5 7.47187 5ZM4.75 5C4.3375 5 4 5.3375 4 5.72187V18.2219C4 18.6625 4.3375 19 4.72188 19C5.10625 19 5.5 18.6625 5.5 18.25V5.72187C5.5 5.3375 5.1625 5 4.75 5ZM16.9719 5C16.725 5 16.5 5.225 16.5 5.47187V18.5C16.5 18.775 16.725 19 16.9719 19C17.2188 19 17.4438 18.775 17.4438 18.5281V5.47187C17.4719 5.225 17.275 5 16.9719 5ZM13.25 5C12.8375 5 12.5 5.3375 12.5 5.72187V18.25C12.5 18.6625 12.8375 19 13.25 19C13.6625 19 14 18.6625 14 18.25V5.75C14 5.3375 13.6625 5 13.25 5ZM19.25 5C18.8375 5 18.5 5.3375 18.5 5.72187V18.25C18.5 18.6625 18.8375 19 19.25 19C19.6625 19 20 18.6625 20 18.25V5.75C20 5.3375 19.6625 5 19.25 5ZM9.75 5C9.3375 5 9 5.3375 9 5.72187V18.2219C9 18.6625 9.3375 19 9.72188 19C10.1625 19 10.5 18.6625 10.5 18.25V5.72187C10.5 5.3375 10.1625 5 9.75 5Z" fill="#3B454F"/>
      </svg>
    </IconWrapper>
  );
}

export default BarCodeRegular;