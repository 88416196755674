import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const LinkDashLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6409 8.68295L14.7622 7.5611C15.7766 6.54752 17.4275 6.54752 18.4401 7.5611C19.4536 8.57519 19.4536 10.2263 18.4401 11.2389L16.0889 13.5901L16.7212 14.0894L19.0056 11.8047C20.3315 10.4786 20.3315 8.32192 19.0056 6.9943C17.6795 5.66819 15.5228 5.66894 14.1952 6.9943L13.0738 8.11615C12.9175 8.27241 12.9175 8.52569 13.0738 8.68195C13.2309 8.83796 13.4859 8.83796 13.6409 8.68295ZM12.4408 10.3606C12.6656 10.5855 12.8403 10.8444 12.9433 11.1217L13.9619 11.9257C13.8983 11.1434 13.5706 10.3813 12.9841 9.7948C12.19 9.00073 11.0987 8.6927 10.0663 8.84971L11.0982 9.64803C11.5907 9.74304 12.0583 9.98056 12.4408 10.3606ZM11.5607 14.4409C11.3357 14.2158 11.1609 13.9567 11.0354 13.6794L10.0173 12.8756C10.0809 13.6581 10.4086 14.4202 10.9952 15.007C11.6582 15.67 12.5293 16.0016 13.4001 16.0016C13.5724 16.0016 13.7436 15.9783 13.9144 15.9524L12.9031 15.1541C12.4133 15.0585 11.9432 14.821 11.5607 14.4409ZM10.3606 16.1186L9.23926 17.2404C8.22493 18.254 6.57403 18.254 5.56145 17.2404C4.54786 16.2263 4.54786 14.5752 5.56145 13.5626L7.91265 11.2112L7.28034 10.7106L4.9944 12.9958C3.66853 14.3219 3.66853 16.4786 4.9944 17.8062C5.65745 18.4688 6.52778 18.8013 7.37785 18.8013C8.24893 18.8013 9.11975 18.4695 9.78281 17.8067L10.9042 16.6849C11.0605 16.5286 11.0605 16.2754 10.9042 16.1191C10.7706 15.9636 10.5156 15.9636 10.3606 16.1186ZM4.64812 6.08573C4.47465 5.94939 4.22308 5.97947 4.08607 6.15176C3.94933 6.32522 3.97904 6.57679 4.1521 6.71381L19.3534 18.7148C19.4264 18.7738 19.5139 18.8013 19.5789 18.8013C19.6973 18.8013 19.8145 18.749 19.8934 18.6494C20.0302 18.4759 20.0005 18.2243 19.8274 18.0871L4.64812 6.08573Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default LinkDashLight;