import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const HamburguerSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.8571 10.7143H5.14286C4.51168 10.7143 4 11.226 4 11.8571C4 12.4883 4.51168 13 5.14286 13H18.8571C19.4883 13 20 12.4883 20 11.8571C20 11.226 19.4883 10.7143 18.8571 10.7143ZM18.8571 16.4286H5.14286C4.51168 16.4286 4 16.9403 4 17.5714C4 18.2026 4.51168 18.7143 5.14286 18.7143H18.8571C19.4883 18.7143 20 18.2026 20 17.5714C20 16.9403 19.4883 16.4286 18.8571 16.4286ZM18.8571 5H5.14286C4.51168 5 4 5.51168 4 6.14286C4 6.77404 4.51168 7.28571 5.14286 7.28571H18.8571C19.4883 7.28571 20 6.77404 20 6.14286C20 5.51168 19.4883 5 18.8571 5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default HamburguerSolid;