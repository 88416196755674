import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ChevronLeftSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1101 19.1307L7.8246 12.5592C7.5601 12.283 7.42842 11.927 7.42842 11.5715C7.42842 11.2161 7.5601 10.86 7.8246 10.5838L14.1101 4.01232C14.6558 3.44257 15.5598 3.42246 16.1301 3.96768C16.7015 4.51343 16.7194 5.41746 16.1747 5.98778L10.8334 11.5715L16.1747 17.1553C16.7205 17.7256 16.7004 18.6296 16.1301 19.1754C15.5598 19.7206 14.6558 19.7005 14.1101 19.1307Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default ChevronLeftSolid;