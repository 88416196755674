import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const WifiRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M19.8902 9.32519C17.8925 7.21182 15.0174 6 11.9997 6C8.98203 6 6.10686 7.21182 4.10925 9.32519C3.95766 9.48615 3.96471 9.73922 4.12487 9.89072C4.28501 10.0427 4.53752 10.0352 4.68978 9.87522C6.53689 7.92011 9.20204 6.80005 11.9997 6.80005C14.7974 6.80005 17.4625 7.92111 19.3101 9.87522C19.3876 9.95773 19.4951 10.0002 19.6001 10.0002C19.6986 10.0002 19.7978 9.96428 19.8752 9.89085C20.0352 9.74022 20.0427 9.4852 19.8902 9.32519ZM11.9997 9.97773C10.0558 9.97773 8.24948 10.7318 6.91441 12.0991C6.7594 12.2779 6.7619 12.5329 6.91941 12.6854C7.07879 12.8409 7.33193 12.8362 7.48519 12.6791C8.66951 11.4678 10.2721 10.8003 11.9997 10.8003C13.7273 10.8003 15.3299 11.4676 16.515 12.6801C16.5925 12.7604 16.695 12.8004 16.8 12.8004C16.9008 12.8004 17.0016 12.7629 17.0797 12.6863C17.2376 12.5324 17.2407 12.2786 17.086 12.1208C15.7499 10.7528 13.9448 9.97773 11.9997 9.97773ZM11.9772 13.978C11.0934 13.978 10.3771 14.6945 10.3771 15.5783C10.3771 16.4621 11.0936 17.1786 11.9772 17.1786C12.8608 17.1786 13.5773 16.4621 13.5773 15.5783C13.5773 14.6945 12.8823 13.978 11.9772 13.978ZM11.9997 16.4006C11.5587 16.4006 11.1997 16.0416 11.1997 15.6006C11.1997 15.1595 11.5597 14.8005 11.9997 14.8005C12.4397 14.8005 12.7997 15.1595 12.7997 15.6006C12.7997 16.0416 12.4397 16.4006 11.9997 16.4006Z" fill="#3B454F"/>
      </svg>
    </IconWrapper>
  );
}

export default WifiRegular;