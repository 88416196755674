import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PlusLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 12C20 12.3402 19.7248 12.6154 19.3846 12.6154H12.6154V19.3846C12.6154 19.7248 12.3402 20 12 20C11.6598 20 11.3846 19.7248 11.3846 19.3846V12.6154H4.61538C4.27523 12.6154 4 12.3402 4 12C4 11.6598 4.27523 11.3846 4.61538 11.3846H11.3846V4.61538C11.3846 4.27523 11.6598 4 12 4C12.3402 4 12.6154 4.27523 12.6154 4.61538V11.3846H19.3846C19.7248 11.3846 20 11.6598 20 12Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PlusLight;