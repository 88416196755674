import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const RoundAlertGreen: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9999 16.2668V12.0002M11.9999 7.7335H12.0106M22.6666 12.0002C22.6666 17.8912 17.891 22.6668 11.9999 22.6668C6.10888 22.6668 1.33325 17.8912 1.33325 12.0002C1.33325 6.10912 6.10888 1.3335 11.9999 1.3335C17.891 1.3335 22.6666 6.10912 22.6666 12.0002Z" stroke="#1ECB4F" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </IconWrapper>
  );
}

export default RoundAlertGreen;