import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const InfoSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4C7.58125 4 4 7.58125 4 12C4 16.4187 7.58125 20 12 20C16.4187 20 20 16.4187 20 12C20 7.58125 16.4187 4 12 4ZM12 8C12.5522 8 13 8.44781 13 9C13 9.55219 12.5522 10 12 10C11.4478 10 11 9.55312 11 9C11 8.44688 11.4469 8 12 8ZM13.25 16H10.75C10.3375 16 10 15.6656 10 15.25C10 14.8344 10.3359 14.5 10.75 14.5H11.25V12.5H11C10.5859 12.5 10.25 12.1641 10.25 11.75C10.25 11.3359 10.5875 11 11 11H12C12.4141 11 12.75 11.3359 12.75 11.75V14.5H13.25C13.6641 14.5 14 14.8359 14 15.25C14 15.6641 13.6656 16 13.25 16Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default InfoSolid;