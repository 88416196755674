import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const EyeRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9036 11.6382C18.3967 8.20905 15.4149 5.88892 12 5.88892C8.58506 5.88892 5.60244 8.21058 4.09636 11.6382C4.04339 11.7612 4 11.9734 4 12.1111C4 12.2494 4.04339 12.461 4.09636 12.5841C5.60331 16.0132 8.58506 18.3334 12 18.3334C15.4149 18.3334 18.3976 16.0116 19.9036 12.5841C19.9566 12.461 20 12.2488 20 12.1111C20 11.9729 19.9566 11.7612 19.9036 11.6382ZM12 17C9.23669 17 6.68989 15.1251 5.34511 12.1112C6.69472 9.0935 9.23919 7.22225 12 7.22225C14.7633 7.22225 17.3101 9.09719 18.6549 12.111C17.3053 15.1288 14.7608 17 12 17ZM12 8.55558C10.0371 8.55558 8.44444 10.1486 8.44444 12.1111C8.44444 14.0737 10.0371 15.6667 12 15.6667H12.0016C13.9637 15.6667 15.5556 14.0745 15.5556 12.1127V12.1111C15.5556 10.1486 13.963 8.55558 12 8.55558ZM12 14.3334C10.7747 14.3334 9.77778 13.3364 9.77778 12.1111C9.77778 12.0904 9.78331 12.0711 9.78392 12.0505C9.92436 12.0868 10.07 12.1111 10.2222 12.1111C11.2041 12.1111 12 11.3152 12 10.3334C12 10.1811 11.9757 10.0355 11.9393 9.89505C11.96 9.89444 11.9792 9.88892 12 9.88892C13.2253 9.88892 14.2222 10.8858 14.2222 12.1127C14.2222 13.3372 13.226 14.3334 12 14.3334Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default EyeRegular;