import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const BookRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 15V5.5C19 4.67281 18.3281 4 17.5 4H7.5C6.12156 4 5 5.12125 5 6.5V17.5C5 18.8781 6.12156 20 7.5 20H18.25C18.6641 20 19 19.6641 19 19.25C19 18.8359 18.6641 18.5 18.25 18.5H18V16.4078C18.5813 16.2 19 15.65 19 15ZM16.5 18.5H7.5C6.94875 18.5 6.5 18.0519 6.5 17.5C6.5 16.9481 6.94875 16.5 7.5 16.5H16.5V18.5ZM17.5 15H7.5C7.14438 15 6.80625 15.0742 6.5 15.209V6.5C6.5 5.94813 6.94875 5.5 7.5 5.5H17.5V15ZM9.75 9H15.25C15.6656 9 16 8.66563 16 8.25C16 7.83437 15.6656 7.5 15.25 7.5H9.75C9.3375 7.5 9 7.8375 9 8.25C9 8.6625 9.3375 9 9.75 9ZM9.75 11.5H15.25C15.6656 11.5 16 11.1656 16 10.75C16 10.3344 15.6656 10 15.25 10H9.75C9.3375 10 9 10.3375 9 10.75C9 11.1625 9.3375 11.5 9.75 11.5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default BookRegular;