import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ClockRotateLeftRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M12 4C16.4187 4 20 7.58125 20 12C20 16.4187 16.4187 20 12 20C10.3688 20 8.85 19.5125 7.58437 18.6719C7.24062 18.4438 7.14687 17.9781 7.34687 17.6344C7.60312 17.2875 8.06875 17.1938 8.41563 17.4219C9.44063 18.1031 10.6719 18.5 12 18.5C15.5906 18.5 18.5 15.5906 18.5 12C18.5 8.40938 15.5906 5.5 12 5.5C9.7 5.5 7.67812 6.695 6.52156 8.5H8.25C8.66563 8.5 9 8.83437 9 9.25C9 9.66563 8.66563 10 8.25 10H4.75C4.33594 10 4 9.66563 4 9.25V5.75C4 5.33594 4.33594 5 4.75 5C5.16406 5 5.5 5.33594 5.5 5.75V7.33437C6.95156 5.31625 9.32188 4 12 4ZM12 8C12.4156 8 12.75 8.33437 12.75 8.75V11.6906L14.7531 13.7188C15.0719 14.0125 15.0719 14.4875 14.7531 14.7531C14.4875 15.0719 14.0125 15.0719 13.7188 14.7531L11.4688 12.5031C11.3281 12.3906 11.25 12.2 11.25 12V8.75C11.25 8.33437 11.5844 8 12 8Z" fill="#3B454F"/>
      </svg>
    </IconWrapper>
  );
}

export default ClockRotateLeftRegular;