import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PenSquareLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.2631 14.0001C16.9918 14.0001 16.7719 14.224 16.7719 14.5001V18C16.7719 18.5522 16.3319 19 15.7894 19H5.96491C5.42241 19 4.98245 18.5522 4.98245 18V8.00023C4.98245 7.44806 5.42241 7.00025 5.96491 7.00025H9.4035C9.67367 7.00025 9.89472 6.77651 9.89472 6.50026C9.89472 6.22402 9.67367 6.00027 9.4035 6.00027H5.93728C4.8796 6.00027 4 6.89557 4 8.00023V18C4 19.1047 4.8796 20 5.93728 20H15.7342C16.8195 20 17.6715 19.1047 17.6715 18L17.7544 14.5001C17.7544 14.2251 17.5333 14.0001 17.2631 14.0001ZM19.2864 5.31872L18.4227 4.43937C18.135 4.14647 17.7577 4 17.3807 4C17.0037 4 16.6264 4.1465 16.3387 4.43937L8.98595 11.9252C8.71271 12.2033 8.52543 12.5595 8.44867 12.947L7.93718 15.5516C7.89064 15.7886 8.0716 16.0004 8.29701 16.0004C8.32112 16.0004 8.3457 15.998 8.37066 15.9929L10.9296 15.472C11.31 15.3945 11.6594 15.2043 11.9336 14.9251L19.2866 7.44092C19.8636 6.85432 19.8636 5.90465 19.2864 5.31872ZM11.2395 14.1907C11.1019 14.3307 10.9285 14.4253 10.7375 14.4641L9.08174 14.8013L9.41271 13.1439C9.4509 12.9495 9.5438 12.7726 9.68147 12.6326L15.1556 7.06088L16.714 8.6471L11.2395 14.1907ZM18.5925 6.73307L17.4068 7.93992L15.8484 6.3537L17.0341 5.14685C17.1592 5.01948 17.3054 5.00042 17.3813 5.00042C17.4572 5.00042 17.6035 5.01946 17.7286 5.14685L18.5922 6.02621C18.7184 6.1534 18.7368 6.30214 18.7368 6.37964C18.7368 6.45683 18.7184 6.60557 18.5925 6.73307Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PenSquareLight;