import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const UploadSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.29375 9.70625C7.68438 10.0966 8.3175 10.0969 8.70781 9.70628L11 7.41563V15C11 15.5522 11.4478 16 12 16C12.5522 16 13 15.5522 13 15V7.41563L15.2931 9.70875C15.6834 10.0991 16.3166 10.0991 16.7072 9.70872C17.0975 9.31841 17.0975 8.68528 16.7072 8.29466L12.7072 4.29466C12.5125 4.09766 12.2563 4 12 4C11.7438 4 11.4875 4.09766 11.2937 4.29297L7.29375 8.29375C6.9025 8.68437 6.9025 9.31563 7.29375 9.70625ZM19 15H14C14 16.1047 13.1047 17 12 17C10.8953 17 10 16.1047 10 15H5C4.44781 15 4 15.4478 4 16V19C4 19.5522 4.44781 20 5 20H19C19.5522 20 20 19.5522 20 19V16C20 15.4469 19.5531 15 19 15ZM17.5 18.25C17.0875 18.25 16.75 17.9125 16.75 17.5C16.75 17.0875 17.0875 16.75 17.5 16.75C17.9125 16.75 18.25 17.0875 18.25 17.5C18.25 17.9125 17.9125 18.25 17.5 18.25Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default UploadSolid;