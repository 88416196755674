import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const BookSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 14.5V5.5C19 4.67156 18.3281 4 17.5 4H8C6.34312 4 5 5.34312 5 7V17C5 18.6569 6.34312 20 8 20H18C18.5522 20 19 19.5522 19 19.0281C19 18.6619 18.7935 18.3556 18.5 18.1812V15.6388C18.8062 15.3375 19 14.9438 19 14.5ZM9.47188 8H15.4719C15.775 8 16 8.225 16 8.5C16 8.775 15.775 9 15.5 9H9.47188C9.225 9 9 8.775 9 8.5C9 8.225 9.225 8 9.47188 8ZM9.47188 10H15.4719C15.775 10 16 10.225 16 10.5C16 10.775 15.775 11 15.5 11H9.47188C9.225 11 9 10.775 9 10.5C9 10.225 9.225 10 9.47188 10ZM17 18H8C7.44781 18 7 17.5522 7 17C7 16.4478 7.44781 16 8 16H17V18Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default BookSolid;