import { useTransition, type ReactNode } from "react";
import { PopoverContainer } from "./styles";

interface Props {
  content: ReactNode;
  children: ReactNode;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void
}

export default function Popover({ content, children, isVisible,  setIsVisible}: Props) {
  const [, startTransition] = useTransition();

  const togglePopover = () => startTransition(() => setIsVisible(!isVisible));
  const closePopover = () => startTransition(() => setIsVisible(false));

  return (
    <PopoverContainer>
      <button
        style={{ height: 'auto' }}
        className="border-none bg-transparent"
        aria-haspopup="menu"
        aria-expanded={isVisible}
        onClick={togglePopover}
      >
        {children}
      </button>
      {isVisible && (
        <div
          role="dialog"
          aria-hidden={!isVisible}
          onMouseLeave={closePopover}
          className="z-10 absolute w-max mt-quark p-nano rounded-sm border-hairline border-neutrals-gray1 bg-white shadow-level3 duration-300 ease-in-out"
          style={{ margin: '0'}}
        >
          {content}
        </div>
      )}
    </PopoverContainer>
  );
}