import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const EyeSlashSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_346_11177)">
          <path d="M12.1278 14.7872L9.64445 12.8408C9.852 13.9548 10.826 14.8001 11.9999 14.8001C12.0437 14.8001 12.0846 14.7895 12.1278 14.7872ZM12.0015 16.0001H11.9999C10.0124 16.0001 8.39997 14.3872 8.39997 12.4001C8.39997 12.2304 8.4272 12.0681 8.44995 11.9046L6.03215 10.0096C5.58787 10.6032 5.20032 11.2607 4.8867 11.9744C4.83905 12.0852 4.79997 12.2761 4.79997 12.4001C4.79997 12.5245 4.83905 12.715 4.8867 12.8257C6.24295 15.9118 8.92652 18 11.9999 18C13.1365 18 14.2192 17.7127 15.2018 17.1966L13.3391 15.7366C12.9249 15.9031 12.4751 16.0001 12.0015 16.0001ZM19.7703 17.7275L17.1783 15.6961C17.9625 14.8969 18.6274 13.9313 19.1132 12.8257C19.1609 12.715 19.1999 12.524 19.1999 12.4001C19.1999 12.2757 19.1609 12.0852 19.1132 11.9744C17.7569 8.88828 15.0734 6.80018 11.9999 6.80018C10.4327 6.80018 8.9692 7.34802 7.72912 8.29L4.97035 6.12773C4.86017 6.0418 4.72972 6 4.6008 6C4.42267 6 4.24612 6.0789 4.12815 6.22968C3.92347 6.49062 3.96877 6.86795 4.22972 7.07225L19.0296 18.6721C19.2921 18.8772 19.6687 18.8303 19.8718 18.5701C20.0765 18.3092 20.0312 17.9318 19.7703 17.7275ZM15.5999 12.4017C15.5999 13.023 15.4326 13.6003 15.1515 14.1076L14.1876 13.352C14.3186 13.0599 14.3999 12.7414 14.3999 12.4001C14.3999 11.0752 13.3248 10.0001 11.9999 10.0001H11.9945C11.937 10.0009 11.8606 10.0112 11.7884 10.0192C11.9192 10.251 11.9999 10.515 11.9999 10.8001C11.9999 11.0544 11.9355 11.2911 11.829 11.5034L9.59277 9.75067C10.2294 9.16607 11.0675 8.80015 11.9999 8.80015C13.9874 8.80015 15.5999 10.413 15.5999 12.4001V12.4017Z" fill="currentColor"/>
        </g>
        <defs>
          <clipPath id="clip0_346_11177">
            <rect width="16" height="12.8" fill="white" transform="translate(4 6)"/>
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
}

export default EyeSlashSolid;