import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const DocumentCheckSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 14.5C11 11.9878 12.695 9.86834 15 9.21319V5C15 3.89538 14.1046 3 13 3H5C3.89544 3 3 3.89538 3 5V17C3 18.1046 3.89544 19 5 19H13C13.1049 19 13.2058 18.9847 13.3063 18.9691C11.9128 17.9703 11 16.3414 11 14.5ZM5.5 7H12.5C12.7762 7 13 7.22388 13 7.5C13 7.77612 12.7762 8 12.5 8H5.5C5.22388 8 5 7.77612 5 7.5C5 7.22388 5.22388 7 5.5 7ZM8.5 14H5.5C5.22388 14 5 13.7761 5 13.5C5 13.2239 5.22388 13 5.5 13H8.5C8.77619 13 9 13.2239 9 13.5C9 13.7761 8.77619 14 8.5 14ZM10.5 11H5.5C5.22388 11 5 10.7761 5 10.5C5 10.2239 5.22388 10 5.5 10H10.5C10.7762 10 11 10.2239 11 10.5C11 10.7761 10.7762 11 10.5 11ZM16.5001 10C14.0145 10 12 12.0145 12 14.5C12 16.9855 14.0145 19 16.5001 19C18.9855 19 21 16.9855 21 14.5C21 12.0145 18.9855 10 16.5001 10ZM18.6035 13.687L16.2705 16.02C16.1728 16.1177 16.0449 16.1665 15.917 16.1665C15.7891 16.1665 15.6611 16.1177 15.5635 16.02L14.3965 14.8535C14.2012 14.6582 14.2012 14.3418 14.3965 14.1465C14.5918 13.9512 14.9082 13.9512 15.1035 14.1465L15.917 14.9595L17.8965 12.98C18.0918 12.7847 18.4082 12.7847 18.6035 12.98C18.7988 13.1753 18.7988 13.4917 18.6035 13.687Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default DocumentCheckSolid;