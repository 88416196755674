import React from "react";
import Route from "models/Route";
import { getPath } from "utils/pathHandler";

const Login = React.lazy(() => import("pages/public/Login"));
const NewPassword = React.lazy(() => import("pages/public/NewPassword"));
const RenewPassword = React.lazy(() => import("pages/public/RenewPassword"));
const FirstAccess = React.lazy(() => import("pages/public/FirstAccess"));

const getPublicPath = getPath.bind(null);

const publics: Route[] = [
  new Route({ path: getPublicPath("signin"), Component: Login }),
  new Route({ path: getPublicPath("newPassword"), Component: NewPassword }),
  new Route({ path: getPublicPath("renewPassword"), Component: RenewPassword }),
  new Route({ path: getPublicPath("firstAccess"), Component: FirstAccess }),
];

export default publics;