import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const UserSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9965 14C13.4318 14 14.5954 12.8359 14.5954 11.4C14.5954 9.96405 13.4318 8.8 11.9965 8.8C10.5613 8.8 9.3976 9.96405 9.3976 11.4C9.3976 12.8359 10.5613 14 11.9965 14ZM13.2472 14.8H10.7528C8.79078 14.8 7.2 16.2923 7.2 18.1333C7.2 18.5015 7.51817 18.8 7.91055 18.8H16.0895C16.4818 18.8 16.8 18.5015 16.8 18.1333C16.8 16.2923 15.2094 14.8 13.2472 14.8ZM16.8 10C17.9046 10 18.8 9.10455 18.8 8C18.8 6.89545 17.9046 6 16.8 6C15.6955 6 14.8 6.89545 14.8 8C14.8 9.10455 15.6955 10 16.8 10ZM8.59765 11.4C8.59765 11.2638 8.62223 11.1343 8.6379 11.0022C8.36533 10.8742 8.06533 10.8 7.748 10.8H6.202C4.98595 10.8 4 11.8446 4 13.1333C4 13.391 4.19718 13.6 4.44038 13.6H9.42675C8.9172 13.0053 8.59765 12.2429 8.59765 11.4ZM7.2 10C8.3046 10 9.2 9.10455 9.2 8C9.2 6.89545 8.3046 6 7.2 6C6.0955 6 5.2 6.89545 5.2 8C5.2 9.10455 6.0955 10 7.2 10ZM17.798 10.8H16.252C15.932 10.8 15.6299 10.8759 15.3555 11.006C15.3709 11.1369 15.3954 11.2651 15.3954 11.4C15.3954 12.2429 15.0758 13.0053 14.5663 13.6H19.5596C19.8028 13.6 20 13.391 20 13.1333C20 11.8446 19.0141 10.8 17.798 10.8Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default UserSolid;