import { useTheme } from "@stardust-ds/react";
import { Container } from "./styles";
import Icon from "assets/icons/Icon";
import useRouteHandler from "hooks/useRouteHandler";

interface IProps {
  children: JSX.Element | React.ReactNode;
}

const AccessWrapper: React.FC<IProps> = ({children}) => {
    const { to }  = useRouteHandler();
    const theme = useTheme();
    
    return (
        <Container>
            <div className="grid-item bg-logo">
                <button className="back-btn" onClick={() => to(-1)}>
                  <Icon
                    icon='arrow_circle_left-light'
                    size={theme.fontSize.xxxl}
                    />
                </button>
                <img className="img-inside" src={require('assets/images/bg_login.webp')}/>
            </div>
            <div className="grid-item mt-md">
              {children}
            </div>
        </Container>
    )
}

export default AccessWrapper;