import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CarretSmallUpRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.82056 16L16.1947 16C17.7953 16 18.6071 14.0561 17.4668 12.9248L13.2651 8.71932C12.5656 8.01491 11.4203 8.01491 10.7163 8.71932L6.51086 12.9248C5.41745 14.055 6.18344 16 7.82056 16ZM12.026 9.99222L16.1992 14.1977L7.82056 14.1977L12.026 9.99222Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CarretSmallUpRegular;