import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PenLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.4121 5.75812L18.24 4.58594C17.8497 4.19537 17.3372 4 16.8278 4C16.3159 4 15.8041 4.19531 15.4135 4.58594L5.40962 14.5906C5.14188 14.8583 4.95557 15.1966 4.87276 15.5659L4.00996 19.4034C3.94684 19.6906 4.1924 20 4.49839 20C4.53111 20 4.56451 19.9968 4.59833 19.99C4.59833 19.99 7.24233 19.4291 8.43574 19.1484C8.79636 19.0635 9.11916 18.8821 9.38103 18.6203L19.4183 8.58281C20.1965 7.80312 20.1934 6.53937 19.4121 5.75812ZM8.67174 17.9125C8.5399 18.0443 8.38262 18.1325 8.20425 18.1745C7.43301 18.3561 6.02805 18.6604 5.15869 18.847L5.84742 15.7842C5.88905 15.5992 5.98189 15.4307 6.11598 15.2967L13.8533 7.55916L16.4395 10.1454L8.67174 17.9125ZM18.709 7.875L17.1456 9.43813L14.5594 6.85188L16.1197 5.29156C16.3091 5.10406 16.5591 5 16.8278 5C17.0966 5 17.3459 5.10406 17.535 5.29297L18.7071 6.46516C19.0965 6.85531 19.0965 7.4875 18.709 7.875Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PenLight;