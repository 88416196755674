import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ArrowLeftRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.409 18.621L4.12307 12.6208C3.95342 12.459 3.85742 12.2346 3.85742 12.0003C3.85742 11.7659 3.95342 11.5415 4.12307 11.3797L10.409 5.3795C10.7516 5.0536 11.2941 5.06588 11.6211 5.4085C11.9481 5.7478 11.9359 6.29246 11.5921 6.62062L6.85378 11.1431L19.0002 11.1431C19.4735 11.1431 19.8574 11.527 19.8574 12.0003C19.8574 12.4735 19.4735 12.8574 19.0002 12.8574L6.85378 12.8574L11.5921 17.3799C11.9358 17.7069 11.9481 18.2516 11.6211 18.592C11.2941 18.9347 10.7516 18.9469 10.409 18.621Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default ArrowLeftRegular;