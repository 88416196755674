import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const SortLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.9757 13.1427H7.99779C7.11365 13.1427 6.66565 14.3748 7.2926 15.0938L11.2551 19.6652C11.6448 20.1116 12.2795 20.1116 12.6692 19.6652L16.6316 15.0938C17.335 14.3748 16.886 13.1427 15.9757 13.1427ZM12.0132 18.857L8.02429 14.2855H16.0022L12.0132 18.857ZM8.02429 10.857H16.0022C16.8864 10.857 17.3344 9.62482 16.7074 8.90625L12.7449 4.33482C12.3552 3.88839 11.7205 3.88839 11.3308 4.33482L7.31517 8.90696C6.69184 9.62482 7.1399 10.857 8.02429 10.857ZM12.0132 5.14161L15.9757 9.71411H7.99779L12.0132 5.14161Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default SortLight;