import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ArrowDown: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
    return (
        <IconWrapper {...props}>
            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2L8 8L14 2" stroke="#3B454F" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </IconWrapper>
    );
}

export default ArrowDown;