import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ChevronRightSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.8899 4.86901L16.1754 11.4405C16.4399 11.7167 16.5716 12.0728 16.5716 12.4282C16.5716 12.7837 16.4399 13.1397 16.1754 13.4159L9.8899 19.9874C9.34418 20.5572 8.44018 20.5773 7.8699 20.0321C7.29851 19.4863 7.28065 18.5823 7.82526 18.012L13.1666 12.4282L7.82526 6.84447C7.27951 6.27415 7.29961 5.37012 7.8699 4.82437C8.44018 4.27915 9.34418 4.29926 9.8899 4.86901Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default ChevronRightSolid;