import React from "react";
import styled, { css } from "styled-components";
import { sizeToPixel } from "utils/stylization";
import { Input } from "@stardust-ds/react";

export interface IContainerProps {
  "max-width"?: string | number;
  padding?: React.ComponentProps<typeof Input>["mBottom"] | "none";
}

export const Container = styled.div<IContainerProps>`
  ${({ theme, "max-width": maxWidth, padding }) => css`
    position: fixed;
    padding: ${padding !== "none"
      ? `${theme.spacing[padding || "xs"]}px`
      : "0px"};
    box-shadow: ${theme.brand.boxShadow.level3};
    width: fit-content;
    height: fit-content;
    max-width: ${sizeToPixel(maxWidth || 579)};
    max-height: 90vh;
    overflow: auto;
    border-radius: ${theme.borderRadius.md}px;
    z-index: -999;
    transform: translate(-50%, -50%);
    top: -100vh;
    left: 50%;
    opacity: 0;
    background-color: ${theme.neutrals.pureWhite};
    display: flex;
    align-items: stretch;
    > * {
      flex: 1;
    }
    transition: opacity ease-in 340ms, top 1ms 400ms, z-index 1ms 400ms;
    &.md-active {
      top: 50%;
      opacity: 1;
      z-index: 99;
      transition: opacity ease-in 340ms 10ms;
    }
    @media screen and (max-width: ${theme.brand.media_query.mobile}) {
      width: calc(100vw - ${theme.spacing.xs}px);
    }
  `}
`;

export const Overlay = styled.div`
  ${({ theme }) => css`
    position: fixed;
    padding: ${theme.spacing.xs}px;
    box-shadow: ${theme.brand.boxShadow.level3};
    width: 100%;
    height: 100%;
    z-index: -999;
    top: -100vh;
    left: 0;
    opacity: 0;
    background-color: rgba(15, 15, 15, 0.02);
    backdrop-filter: blur(1.7px);
    transition: opacity ease-in 340ms, top 0 400ms, z-index 0 400ms;
    &.md-active {
      top: 0;
      opacity: 1;
      z-index: 98;
      transition: opacity ease-in 340ms 10ms;
    }
  `}
`;
