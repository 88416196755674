import { FC } from "react";
import { TUserRole } from "types/user";

const C: FC = () => null;

export interface IRoute {
  path: string;
  Component: FC;
  title?: string;
  role?: TUserRole | TUserRole[];
}

export default class Route implements IRoute {
  path = "";
  Component = C;
  title = "";
  role = undefined as IRoute["role"];
  constructor(data: IRoute) {
    Object.assign(this, data);
  }
}