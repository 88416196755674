import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CircleCheckRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.4688 9.46875L11 12.9406L9.50313 11.4688C9.21016 11.1758 8.73563 11.1758 8.4425 11.4688C8.14937 11.7617 8.14953 12.2362 8.4425 12.5294L10.4425 14.5294C10.6156 14.6781 10.8063 14.75 11 14.75C11.1937 14.75 11.3837 14.6768 11.5303 14.5303L15.5303 10.5303C15.8233 10.2373 15.8233 9.76278 15.5303 9.46966C15.2373 9.17653 14.7625 9.17812 14.4688 9.46875ZM12 4C7.58125 4 4 7.58125 4 12C4 16.4187 7.58125 20 12 20C16.4187 20 20 16.4187 20 12C20 7.58125 16.4187 4 12 4ZM12 18.5C8.41563 18.5 5.5 15.5841 5.5 12C5.5 8.41594 8.41563 5.5 12 5.5C15.5844 5.5 18.5 8.41594 18.5 12C18.5 15.5841 15.5844 18.5 12 18.5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CircleCheckRegular;