import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const UserRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 13.5H10.5C7.46241 13.5 5 15.9624 5 19C5 19.5522 5.44769 20 6 20H18C18.5523 20 19 19.5522 19 19C19 15.9624 16.5376 13.5 13.5 13.5ZM6.53106 18.5C6.77797 16.5295 8.46375 15 10.5 15H13.5C15.5363 15 17.222 16.5295 17.4689 18.5H6.53106ZM12 12C14.2092 12 16 10.2091 16 8C16 5.79084 14.2092 4 12 4C9.79084 4 8 5.79084 8 8C8 10.2091 9.79084 12 12 12ZM12 5.5C13.3785 5.5 14.5 6.62147 14.5 8C14.5 9.37847 13.3785 10.5 12 10.5C10.6215 10.5 9.5 9.37847 9.5 8C9.5 6.62147 10.6215 5.5 12 5.5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default UserRegular;