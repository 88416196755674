import React from 'react';
import { toggleClassName } from 'utils/stylization';
import { Container, Overlay, IContainerProps } from './styles';

export interface IModalProps extends IContainerProps {
  show: boolean;
  handleClose: () => void;
  containerRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}

const Modal: React.FC<IModalProps> = ({ show, handleClose, containerRef, children, "max-width": maxWidth }) => {
  return (
    <>
      <Container
        max-width={maxWidth}
        ref={containerRef}
        className={toggleClassName({ default:'', if: show, ifClass: 'md-active' })}>
        {children}
      </Container>
      <Overlay onClick={handleClose} className={toggleClassName({ if: show, ifClass: 'md-active' })}/>
    </>
  );
}

export default Modal;