import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const DownloadFileRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.8 12.4V7.84C17.8 6.49587 17.8 5.82381 17.5384 5.31042C17.3083 4.85883 16.9412 4.49168 16.4896 4.26158C15.9762 4 15.3041 4 13.96 4H8.84C7.49587 4 6.82381 4 6.31042 4.26158C5.85883 4.49168 5.49168 4.85883 5.26158 5.31042C5 5.82381 5 6.49587 5 7.84V16.16C5 17.5041 5 18.1762 5.26158 18.6896C5.49168 19.1412 5.85883 19.5083 6.31042 19.7384C6.82381 20 7.49584 20 8.83989 20H11.8M13 11.2H8.2M9.8 14.4H8.2M14.6 8H8.2M13.8 17.6L16.2 20M16.2 20L18.6 17.6M16.2 20V15.2" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    </IconWrapper>
  );
}

export default DownloadFileRegular;
