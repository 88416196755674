import { useCallback, useState } from "react";

function useToggle(def = false): [boolean, () => void] {
  const [isEnabled, setIsEnabled] = useState(def);

  const handleToggle = useCallback(() => {
    setIsEnabled((val) => !val);
  }, [setIsEnabled]);

  return [isEnabled, handleToggle];
}

export default useToggle;
