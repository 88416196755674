import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const DocumentCheckRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 11.75C12 11.3359 11.6641 11 11.25 11H6.75C6.33594 11 6 11.3359 6 11.75C6 12.1641 6.33594 12.5 6.75 12.5H11.25C11.6641 12.5 12 12.1641 12 11.75ZM11.8938 18.5H5C4.72431 18.5 4.5 18.2757 4.5 18V6C4.5 5.72431 4.72431 5.5 5 5.5H13C13.2757 5.5 13.5 5.72431 13.5 6V10.8938C13.9573 10.5952 14.4619 10.3648 15 10.2124V6C15 4.89538 14.1046 4 13 4H5C3.89544 4 3 4.89538 3 6V18C3 19.1046 3.89544 20 5 20H13C13.1038 20 13.2034 19.9847 13.3029 19.9694C12.747 19.571 12.2681 19.0737 11.8938 18.5ZM6.75 14C6.33594 14 6 14.3359 6 14.75C6 15.1641 6.33594 15.5 6.75 15.5H8.25C8.66406 15.5 9 15.1641 9 14.75C9 14.3359 8.66406 14 8.25 14H6.75ZM11.25 8H6.75C6.33594 8 6 8.33594 6 8.75C6 9.16406 6.33594 9.5 6.75 9.5H11.25C11.6641 9.5 12 9.16406 12 8.75C12 8.33594 11.6641 8 11.25 8ZM16.5001 11C14.0145 11 12 13.0145 12 15.5C12 17.9855 14.0145 20 16.5001 20C18.9855 20 21 17.9855 21 15.5C21 13.0145 18.9855 11 16.5001 11ZM18.6035 14.687L16.2705 17.02C16.1728 17.1177 16.0449 17.1665 15.917 17.1665C15.7891 17.1665 15.6611 17.1177 15.5635 17.02L14.3965 15.8535C14.2012 15.6582 14.2012 15.3418 14.3965 15.1465C14.5918 14.9512 14.9082 14.9512 15.1035 15.1465L15.917 15.9595L17.8965 13.98C18.0918 13.7847 18.4082 13.7847 18.6035 13.98C18.7988 14.1753 18.7988 14.4917 18.6035 14.687Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default DocumentCheckRegular;