import axios from 'axios';
import { STORE_KEY } from 'constants/APP_CONSTANTS';
import publics from 'routes/publics';

const baseURL = process.env.REACT_APP_API

const redirectToServiceUnavailable = () => {
    const erroPath = '#/503';
    window.location.hash = erroPath;
};

const api = axios.create({
    baseURL
});

api.interceptors.response.use(
    (response) => {
        if(process.env.NODE_ENV === "development"){
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(response);
                }, 1000);
            });
        }
        return response;
    },
    (error) => {
        if (error?.response) {
            const status = error.response.status as number;
            if (status === 401) {
                const hash = window.location.hash
                const pathname = hash.includes('#/') ? hash.split('#/')[1] : hash
                const publicRoutes = publics.filter(({path}) => path.includes(pathname))
                if(!publicRoutes.length){
                    localStorage.removeItem(STORE_KEY);
                    window.location.reload();
                }
            }
            if(status === 503) {
              redirectToServiceUnavailable();
            }
          }
        console.clear();

        return Promise.reject(error);
    }
);

export default api;