import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ArrowsRotateLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M19.4286 4C19.1143 4 18.8571 4.25571 18.8571 4.57143V9.18929C17.7214 6.41857 15.0214 4.57143 12 4.57143C8.35714 4.57143 5.275 7.17536 4.675 10.7607C4.62214 11.075 4.83214 11.3679 5.14357 11.4214C5.17536 11.425 5.20714 11.4286 5.23821 11.4286C5.51221 11.4286 5.75429 11.231 5.80143 10.9521C6.31036 7.91786 8.91786 5.71429 12 5.71429C14.5889 5.71429 16.9036 7.31714 17.8429 9.71429H13.7143C13.4 9.71429 13.1429 9.97143 13.1429 10.2857C13.1429 10.6 13.4 10.8571 13.7143 10.8571H19.4286C19.7429 10.8571 20 10.6 20 10.2857V4.57143C20 4.25571 19.7429 4 19.4286 4ZM18.825 12.5786C18.5119 12.5228 18.2189 12.7359 18.1671 13.0471C17.6893 16.0821 15.0821 18.2857 11.9679 18.2857C9.37893 18.2857 7.06429 16.6829 6.125 14.2857H10.2857C10.6 14.2857 10.8571 14.0286 10.8571 13.7143C10.8571 13.4 10.6 13.1429 10.2857 13.1429H4.57143C4.25571 13.1429 4 13.4 4 13.7143V19.4286C4 19.7429 4.25571 20 4.57143 20C4.88714 20 5.14286 19.7429 5.14286 19.4286V14.8107C6.28 17.5821 8.97857 19.4286 12 19.4286C15.6107 19.4286 18.725 16.8246 19.325 13.2393C19.3786 12.925 19.1679 12.6321 18.825 12.5786Z" fill="#3B454F"/>
      </svg>
    </IconWrapper>
  );
}

export default ArrowsRotateLight;