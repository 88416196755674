import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const UserSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 12C14.2092 12 16 10.2091 16 8C16 5.79091 14.2092 4 12 4C9.791 4 8 5.79091 8 8C8 10.2091 9.791 12 12 12ZM13.5833 13.5H10.4167C7.42528 13.5 5 15.9251 5 18.9166C5 19.5149 5.48509 20 6.08325 20H17.9167C18.5149 20 19 19.5149 19 18.9166C19 15.9251 16.5749 13.5 13.5833 13.5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default UserSolid;