import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const UserLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 12C14.2092 12 16 10.2091 16 8C16 5.79091 14.2092 4 12 4C9.791 4 8 5.79091 8 8C8 10.2091 9.791 12 12 12ZM12 5C13.6543 5 15 6.34575 15 8C15 9.65419 13.6543 11 12 11C10.3457 11 9 9.65419 9 8C9 6.34575 10.3457 5 12 5ZM13.5833 13.5H10.4167C7.42528 13.5 5 15.9251 5 18.9166C5 19.5149 5.48509 20 6.08325 20H17.9167C18.5149 20 19 19.5149 19 18.9166C19 15.9251 16.5749 13.5 13.5833 13.5ZM17.9167 19H6.08325C6.03734 19 6 18.9626 6 18.9166C6 16.4812 7.98131 14.5 10.4167 14.5H13.5833C16.0187 14.5 18 16.4812 18 18.9166C18 18.9626 17.9627 19 17.9167 19Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default UserLight;