import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const StarRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8916 9.35906L15.3178 8.69359L13.2731 4.55641C13.0896 4.18659 12.7311 3.99994 12.3727 4C12.0166 4 11.6607 4.18431 11.4768 4.55641L9.4321 8.69359L4.85838 9.35906C4.03757 9.47772 3.70882 10.4869 4.30369 11.0649L7.61338 14.2833L6.83072 18.8297C6.71938 19.4795 7.23747 20 7.81844 20C7.97257 20 8.13113 19.9634 8.28354 19.8828L12.375 17.7361L16.4664 19.8828C16.6184 19.9626 16.7766 19.9988 16.9303 19.9988C17.5118 19.9988 18.0307 19.4799 17.9193 18.8297L17.1366 14.2833L20.4463 11.0649C21.0412 10.4869 20.7124 9.47772 19.8916 9.35906ZM15.569 14.0185L16.2691 18.0853L12.6073 16.164C12.4618 16.0877 12.2881 16.0877 12.1427 16.164L8.48094 18.0853L9.18104 14.0185C9.209 13.856 9.15507 13.6902 9.03685 13.5752L6.07741 10.6975L10.1679 10.1024C10.3306 10.0787 10.4713 9.97653 10.5441 9.82912L12.375 6.12462L14.2059 9.82912C14.2787 9.97653 14.4194 10.0787 14.5821 10.1024L18.6725 10.6975L15.7131 13.5752C15.5949 13.6902 15.541 13.856 15.569 14.0185Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default StarRegular;