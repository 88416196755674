import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PlusSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 12C20 12.8498 19.3113 13.5385 18.4615 13.5385H13.5385V18.4615C13.5385 19.3113 12.8498 20 12 20C11.1502 20 10.4615 19.3113 10.4615 18.4615V13.5385H5.53846C4.68869 13.5385 4 12.8498 4 12C4 11.1502 4.68869 10.4615 5.53846 10.4615H10.4615V5.53846C10.4615 4.68869 11.1502 4 12 4C12.8498 4 13.5385 4.68869 13.5385 5.53846V10.4615H18.4615C19.3113 10.4615 20 11.1502 20 12Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PlusSolid;