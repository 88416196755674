import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const SearchSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.7803 18.0125L15.8945 14.1266C16.5923 13.0907 17 11.843 17 10.5C17 6.91015 14.0898 4 10.5 4C6.91015 4 4 6.91015 4 10.5C4 14.0898 6.91015 17 10.5 17C11.843 17 13.0907 16.5923 14.1266 15.8945L18.0125 19.7803C18.3054 20.0732 18.7803 20.0732 19.0732 19.7803L19.7803 19.0732C20.0732 18.7803 20.0732 18.3054 19.7803 18.0125ZM6.5 10.5C6.5 8.29437 8.29437 6.5 10.5 6.5C12.7056 6.5 14.5 8.29437 14.5 10.5C14.5 12.7056 12.7056 14.5 10.5 14.5C8.29437 14.5 6.5 12.7056 6.5 10.5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default SearchSolid;