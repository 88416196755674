import { Button, Typography, toast } from "@stardust-ds/react"
import Modal from "components/Modal"
import { useTheme } from "styled-components"
import { AxiosError } from 'axios';
import useStore from 'hooks/useStore';
import { useCallback, useState } from "react";
import { Container } from './styles';
import SpinnerAnimation from "components/SpinnerAnimation/SpinnerAnimation";
import Input from "components/Input";
import { updatePasswordSchema } from "validations/yup/publicSchemas";
import { useFormik } from "formik";
import { IUpdateResponse } from "interfaces/password/IUpdatePassword";

interface IUpdatePasswordModalProps {
    showUpdatePasswordModal: boolean,
    setShowUpdatePasswordModal: (showUpdatePasswordModal: boolean) => void
}

export const UpdatePasswordModal: React.FC<IUpdatePasswordModalProps> = ({ showUpdatePasswordModal, setShowUpdatePasswordModal }) => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { api } = useStore(state => ({
        api: state.getApi(),
    }));

    const formik = useFormik({
        initialValues: {
          currentPassword: '',
          password: '',
          confirmPassword: '',
        },
        validateOnChange: false,
        validationSchema: updatePasswordSchema,
        onSubmit: async (data) => {
          try {
            setIsLoading(true)
            await api.put<IUpdateResponse>(`/auth/update_password`, data);
            toast({
                type: "success",
                description: `Senha alterada com sucesso!`,
                position: "bottom-right",
              });
            setShowUpdatePasswordModal(false);
          } catch(err) {
            if(err instanceof AxiosError){
              toast({
                type: "error",
                description: err?.response?.data?.message,
                position: "bottom-right",
              });
            }
          } finally {
            setIsLoading(false)
            formik.setSubmitting(false);
          }
        }
      });
        const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
            const name = e.target.name;
            formik.setFieldError(name, '');
            formik.handleChange(e);
        }, [formik]);

    const handleModalClose = () => {
        setShowUpdatePasswordModal(false)
    }


    return (
        <Modal max-width={360} show={showUpdatePasswordModal} handleClose={handleModalClose}>
            <Container>
            <Typography data-title fontSize='lg' mTop="md" color={theme.brand.color.primary.medium}>
            Nova Senha
            </Typography>
            <Typography data-title fontSize='xs' mTop="nano" fontWeight="normal" mBottom='sm' color={theme.neutrals.gray6}>
            Digite sua senha nova abaixo. Lembre-se, sua senha precisa ter...
            </Typography>
            <Input
                label='Senha Atual'
                name='currentPassword'
                placeholder='Digite aqui'
                mBottom='xxxs'
                maxWidth={'100%'}
                isPass
                value={formik.values.currentPassword}
                error={formik.errors.currentPassword}
                onChange={handleInputChange}
                />
            <Input
                label='Nova senha'
                name='password'
                placeholder='Digite aqui'
                mBottom='xxxs'
                maxWidth={'100%'}
                isPass
                value={formik.values.password}
                error={formik.errors.password}
                onChange={handleInputChange}
                />
            <Input
                label='Confirmação de Senha' 
                name='confirmPassword'
                isPass
                placeholder='Digite aqui'
                mBottom='xxxs'
                maxWidth={'100%'}
                value={formik.values.confirmPassword}
                error={formik.errors.confirmPassword}
                onChange={handleInputChange}
                />
            <Button onClick={() => formik.handleSubmit()} type='button' disabled={formik.isSubmitting} mBottom='xxxs' bgColor={theme.brand.color.primary.pure} isFullWidth mTop='sm' bRadius="pill" style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {isLoading ?
                <div style={{ 
                  position: 'absolute', 
                  top: '50%', 
                  left: '50%', 
                  transform: 'translate(-50%, -50%)',
                  width: '24px',
                  height: '24px'
                }}>
                  <SpinnerAnimation />
                </div>
                    :
                'SALVAR NOVA SENHA'}
            </Button>
            </Container>
        </Modal>
    );
}