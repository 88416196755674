import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const GaugeSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 6C7.58333 6 4 9.58333 4 14C4 15.4653 4.39583 16.8403 5.08333 18.0208C5.23958 18.2882 5.53819 18.4444 5.84722 18.4444H18.1528C18.4618 18.4444 18.7604 18.2882 18.9167 18.0208C19.6042 16.8403 20 15.4653 20 14C20 9.58333 16.4167 6 12 6ZM12 7.77778C12.4896 7.77778 12.8889 8.17708 12.8889 8.66667C12.8889 9.15625 12.4896 9.55556 12 9.55556C11.5104 9.55556 11.1111 9.15625 11.1111 8.66667C11.1111 8.17708 11.5104 7.77778 12 7.77778ZM6.66667 15.7778C6.17708 15.7778 5.77778 15.3785 5.77778 14.8889C5.77778 14.3993 6.17708 14 6.66667 14C7.15625 14 7.55556 14.3993 7.55556 14.8889C7.55556 15.3785 7.15625 15.7778 6.66667 15.7778ZM8 11.3333C7.51042 11.3333 7.11111 10.934 7.11111 10.4444C7.11111 9.95486 7.51042 9.55556 8 9.55556C8.48958 9.55556 8.88889 9.95486 8.88889 10.4444C8.88889 10.934 8.48958 11.3333 8 11.3333ZM13.5312 16.6667H10.4688C10.316 16.4028 10.2222 16.1042 10.2222 15.7778C10.2222 14.7951 11.0174 14 12 14C12.1562 14 12.3056 14.0278 12.4514 14.066L15.4653 10.0451C15.6875 9.75 16.1042 9.69097 16.3993 9.90972C16.6944 10.1319 16.7535 10.5486 16.5347 10.8438L13.5174 14.8646C13.6806 15.1319 13.7778 15.441 13.7778 15.7778C13.7778 16.1042 13.684 16.4028 13.5312 16.6667ZM17.3333 15.7778C16.8438 15.7778 16.4444 15.3785 16.4444 14.8889C16.4444 14.3993 16.8438 14 17.3333 14C17.8229 14 18.2222 14.3993 18.2222 14.8889C18.2222 15.3785 17.8229 15.7778 17.3333 15.7778Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default GaugeSolid;