import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const UsersLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9965 14C13.4318 14 14.5954 12.8359 14.5954 11.4C14.5954 9.96405 13.4318 8.8 11.9965 8.8C10.5613 8.8 9.3976 9.96405 9.3976 11.4C9.3976 12.8359 10.5613 14 11.9965 14ZM11.9965 9.6C12.9884 9.6 13.7954 10.4075 13.7954 11.4C13.7954 12.3925 12.9884 13.2 11.9965 13.2C11.0046 13.2 10.1976 12.3925 10.1976 11.4C10.1976 10.4075 11.0046 9.6 11.9965 9.6ZM13.2472 14.8H10.7528C8.79078 14.8 7.2 16.2923 7.2 18.1333C7.2 18.5015 7.51817 18.8 7.91055 18.8H16.0895C16.4818 18.8 16.8 18.5015 16.8 18.1333C16.8 16.2923 15.2094 14.8 13.2472 14.8ZM8.00375 18C8.0793 16.6648 9.28345 15.6 10.7528 15.6H13.2472C14.7166 15.6 15.9207 16.6648 15.9963 18H8.00375ZM16.8 10C17.9046 10 18.8 9.10455 18.8 8C18.8 6.89545 17.9046 6 16.8 6C15.6955 6 14.8 6.89545 14.8 8C14.8 9.10455 15.6955 10 16.8 10ZM16.8 6.8C17.4617 6.8 18 7.33833 18 8C18 8.66168 17.4617 9.2 16.8 9.2C16.1383 9.2 15.6 8.66168 15.6 8C15.6 7.33833 16.1383 6.8 16.8 6.8ZM7.2 10C8.3046 10 9.2 9.10455 9.2 8C9.2 6.89545 8.3046 6 7.2 6C6.0955 6 5.2 6.89545 5.2 8C5.2 9.10455 6.0955 10 7.2 10ZM7.2 6.8C7.86168 6.8 8.4 7.33833 8.4 8C8.4 8.66168 7.86168 9.2 7.2 9.2C6.53833 9.2 6 8.66168 6 8C6 7.33833 6.53833 6.8 7.2 6.8ZM18.027 10.8H16.4C16.1211 10.8 15.8481 10.8625 15.5883 10.986C15.3887 11.0809 15.3035 11.3195 15.3984 11.5188C15.4934 11.718 15.7328 11.8035 15.9313 11.7086C16.0832 11.6367 16.2406 11.6 16.4 11.6H18.027C18.6738 11.6 19.2 12.1684 19.2 12.8668V13.2C19.2 13.4211 19.3789 13.6 19.6 13.6C19.8211 13.6 20 13.4211 20 13.2V12.8668C20 11.7274 19.1149 10.8 18.027 10.8ZM8.06875 11.7086C8.2672 11.8035 8.50702 11.718 8.60155 11.5188C8.69647 11.3195 8.61132 11.0809 8.41172 10.986C8.15195 10.8625 7.8789 10.8 7.6 10.8H5.97305C4.88515 10.8 4 11.7274 4 12.8668V13.2C4 13.4211 4.1789 13.6 4.4 13.6C4.6211 13.6 4.8 13.4211 4.8 13.2V12.8668C4.8 12.1684 5.32617 11.6 5.97305 11.6H7.6C7.75938 11.6 7.9168 11.6367 8.06875 11.7086Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default UsersLight;