import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PersonMaleSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.8469 12.4688L14.6419 8.96562C14.275 8.35312 13.6406 8 12.9469 8H11.0531C10.3587 8 9.72406 8.35156 9.35687 8.94031L7.15228 12.4688C6.85931 12.9369 7.00191 13.5538 7.46978 13.8466C7.93853 14.1395 8.55572 13.9969 8.84759 13.5281L9.75009 12.085L9.72187 19C9.72187 19.5522 10.1697 20 10.7219 20C11.2741 20 11.6937 19.5522 11.6937 19V14.5H12.1937V19C12.1937 19.5522 12.6416 20 13.1937 20C13.7459 20 14.1937 19.5522 14.1937 19V12.0875L15.0962 13.5312C15.3406 13.8344 15.6687 14 16 14C16.1807 14 16.3644 13.9512 16.5294 13.8482C16.9969 13.5562 17.1406 12.9375 16.8469 12.4688ZM12 7C12.8281 7 13.5 6.32812 13.5 5.5C13.5 4.67188 12.8281 4 12 4C11.1719 4 10.5 4.67188 10.5 5.5C10.5 6.32812 11.1719 7 12 7Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PersonMaleSolid;