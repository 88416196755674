import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const InfoRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4C7.58125 4 4 7.58125 4 12C4 16.4187 7.58125 20 12 20C16.4187 20 20 16.4187 20 12C20 7.58125 16.4187 4 12 4ZM12 18.5C8.41563 18.5 5.5 15.5841 5.5 12C5.5 8.41594 8.41563 5.5 12 5.5C15.5844 5.5 18.5 8.41594 18.5 12C18.5 15.5841 15.5844 18.5 12 18.5ZM13.25 14.5H12.75V11.75C12.75 11.3375 12.4156 11 12 11H11C10.5875 11 10.25 11.3375 10.25 11.75C10.25 12.1625 10.5875 12.5 11 12.5H11.25V14.5H10.75C10.3375 14.5 10 14.8375 10 15.25C10 15.6625 10.3375 16 10.75 16H13.25C13.6641 16 14 15.6641 14 15.25C14 14.8359 13.6656 14.5 13.25 14.5ZM12 10C12.5522 10 13 9.55219 13 9C13 8.44781 12.5522 8 12 8C11.4478 8 11 8.44688 11 9C11 9.55312 11.4469 10 12 10Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default InfoRegular;