import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const SortSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.86433 10.8577H16.1357C16.9044 10.8577 17.2884 9.79419 16.7463 9.17494L12.6122 4.28952C12.4432 4.09646 12.2225 4 12.0019 4C11.7814 4 11.5616 4.09646 11.3941 4.28952L7.25371 9.17565C6.71162 9.79347 7.09547 10.8577 7.86433 10.8577ZM16.1338 13.1433H7.86433C7.09562 13.1433 6.71158 14.2064 7.25374 14.826L11.3941 19.7114C11.5591 19.9036 11.7528 20 11.9997 20C12.2201 20 12.4409 19.9035 12.61 19.7104L16.7441 14.825C17.2869 14.2075 16.9025 13.1433 16.1338 13.1433Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default SortSolid;