import React, { useCallback, useState } from 'react';
import { AvatarIcon, Menu, MenuItem } from './styles';
import useStore from 'hooks/useStore';
import { Flex, useTheme } from '@stardust-ds/react';
import Icon from 'assets/icons/Icon';
import Popover from 'components/Popover/Popover';

interface IProps {
  setShowUpdatePasswordModal: (showUpdatePasswordModal: boolean) => void;
}

const AvatarMenu: React.FC<IProps>  = ({setShowUpdatePasswordModal}) => {
  const theme = useTheme();
  const signout = useStore(state => state.signout);
  const [isVisible, setIsVisible] = useState(false);

  const handleLogout = useCallback(async () => {
    try {
      await signout()
    } catch (err) {
      //
    }
  }, [signout]);

  const popoverContent = (
    <Menu>
      <MenuItem onClick={() => setShowUpdatePasswordModal(true)}>
        <Icon
          icon={'locker-light'}
          size={theme.fontSize.xs}
          color={'white'}
        />
        Trocar Senha
      </MenuItem>
      {/* <MenuItem>
        <Icon
          icon={'help-light'}
          size={theme.fontSize.xs}
          color={'white'}
        />
        Ajuda</MenuItem> */}
      <MenuItem onClick={handleLogout}>
        <Icon
          icon={'logout-light'}
          size={theme.fontSize.xs}
          color={'white'}
        />Sair</MenuItem>
    </Menu>
  )

  const popoverChildren = (<Flex align="center" gap="0" cursor={'pointer'}>
      <AvatarIcon>
      <Icon
        icon={'avatar-light'}
        size={theme.fontSize.xxxs}
        color={'#E9EBEE'}
      />
      </AvatarIcon>

      <Icon
        icon={isVisible ? 'arrow_up-light' : 'arrow_down-light'}
        size={theme.fontSize.xxxs}
        color={'white'}
      />
    
  </Flex>)

  return (
    <Popover content={popoverContent}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
    >
      {popoverChildren}
    </Popover>
  );
};

export default AvatarMenu;
