import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const DocumentSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 4V8H18L14 4ZM13 8V4H7.5C6.67156 4 6 4.67163 6 5.5V18.5C6 19.3284 6.67156 20 7.5 20H16.5C17.3284 20 18 19.3284 18 18.5V9H14C13.4477 9 13 8.55225 13 8Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default DocumentSolid;