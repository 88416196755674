import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const Avatar: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
    return (
        <IconWrapper {...props}>
            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8145 12.3334C10.8145 11.5064 10.8145 11.0929 10.7124 10.7564C10.4826 9.99887 9.88978 9.40603 9.1322 9.17622C8.79573 9.07415 8.38223 9.07415 7.55523 9.07415H4.59227C3.76527 9.07415 3.35177 9.07415 3.01529 9.17622C2.25772 9.40603 1.66488 9.99887 1.43508 10.7564C1.33301 11.0929 1.33301 11.5064 1.33301 12.3334M8.74042 4.33341C8.74042 5.80617 7.54651 7.00008 6.07375 7.00008C4.60099 7.00008 3.40708 5.80617 3.40708 4.33341C3.40708 2.86066 4.60099 1.66675 6.07375 1.66675C7.54651 1.66675 8.74042 2.86066 8.74042 4.33341Z" stroke="#3B454F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </IconWrapper>
    );
}

export default Avatar;