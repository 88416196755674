import React from 'react';

import styled, { css } from 'styled-components';
import { sizeToPixel } from 'utils/stylization';

export interface IIconWrapperProps {
  children: JSX.Element | React.ReactNode;
  size?: number | string;
  color?: string;
  className?: string;
}

const Container = styled.div<IIconWrapperProps>`
  ${(props) => css`
    color: ${props.color || 'currentColor'};
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: ${sizeToPixel(props.size)};
      height: ${sizeToPixel(props.size)};
      path {
        fill: currentColor !important;
      }
    }
  `}
`;

const IconWrapper: React.FC<IIconWrapperProps> = ({ children, size, color, className }) => {
  return (
    <Container className={className} size={size} color={color}>
      {children}
    </Container>
  );
}

export default IconWrapper;