import { ThemeProvider, ToastContainer } from '@stardust-ds/react';
import { HashRouter } from 'react-router-dom';
import Routes from 'routes';
import Global, {theme} from './styles/global';

function App() {
  return (
    <ThemeProvider theme={theme}>
    <Global/>
    <main className='container'>
    <HashRouter>
      <Routes/>
    </HashRouter>
    </main>
    <ToastContainer />
  </ThemeProvider>
  );
}

export default App;
