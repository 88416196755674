import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const DocumentCheckBlue: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
        <svg width="29" height="35" viewBox="0 0 29 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 1.93125V8.54012C17.5 9.4362 17.5 9.88424 17.6744 10.2265C17.8278 10.5276 18.0726 10.7723 18.3736 10.9257C18.7159 11.1001 19.1639 11.1001 20.06 11.1001H26.6689M9.5 23.9L12.7 27.1L19.9 19.9M17.5 1.5H9.18C6.49175 1.5 5.14762 1.5 4.12085 2.02317C3.21767 2.48336 2.48336 3.21767 2.02317 4.12085C1.5 5.14762 1.5 6.49175 1.5 9.18V25.82C1.5 28.5083 1.5 29.8524 2.02317 30.8792C2.48336 31.7823 3.21767 32.5166 4.12085 32.9768C5.14762 33.5 6.49175 33.5 9.18 33.5H19.42C22.1083 33.5 23.4524 33.5 24.4792 32.9768C25.3823 32.5166 26.1166 31.7823 26.5768 30.8792C27.1 29.8524 27.1 28.5083 27.1 25.82V11.1L17.5 1.5Z" stroke="#1A48FA" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </IconWrapper>
  );
}

export default DocumentCheckBlue;