import Lottie from 'lottie-react';
import React from 'react';


const SpinnerAnimation: React.FC = () => {

  return (
    <>
      <Lottie
        animationData={require('assets/spinner-animation.json')}
        className='animation-content'
      />
    </>
  );
}

export default SpinnerAnimation;