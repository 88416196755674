import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ArrowsRotateRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M14.6236 9.06811C14.1977 8.94314 13.7551 9.18789 13.6322 9.61166C12.9106 12.1275 10.5945 13.8638 7.97167 13.8638C5.75447 13.8638 3.74589 12.588 2.75609 10.6644H6.10206C6.54364 10.6644 6.9019 10.3062 6.9019 9.86461C6.9019 9.42304 6.5753 9.06478 6.13206 9.06478H0.799833C0.358259 9.06478 0 9.42304 0 9.86461V15.1968C0 15.6384 0.358259 15.9967 0.799833 15.9967C1.24141 15.9967 1.59967 15.6384 1.59967 15.1968V11.7942C2.92606 14.0104 5.33889 15.4634 7.97167 15.4634C11.271 15.4634 14.217 13.2536 15.1402 10.0879C15.2935 9.63466 15.0469 9.22141 14.6236 9.06811ZM15.2002 0C14.7603 0 14.4003 0.358259 14.4003 0.799833V4.20246C13.0773 1.98459 10.6611 0.533222 8.00167 0.533222C4.70235 0.533222 1.75397 2.7431 0.833493 5.90877C0.709519 6.33201 0.953135 6.77526 1.37705 6.89856C1.80496 7.02457 2.24653 6.77775 2.36851 6.35501C3.09269 3.86919 5.40887 2.13289 8.00167 2.13289C10.2189 2.13289 12.2275 3.40862 13.2172 5.33222H9.86794C9.42804 5.33222 9.06811 5.69215 9.06811 6.13206C9.06811 6.57196 9.42804 6.93189 9.86794 6.93189H15.2002C15.6417 6.93189 16 6.57363 16 6.13206V0.799833C16 0.358259 15.6434 0 15.2002 0Z" fill="#3B454F"/>
      </svg>
    </IconWrapper>
  );
}

export default ArrowsRotateRegular;