import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CheckListSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.69238 10.2479L5.97169 12.1605L5.28029 11.4695C4.98732 11.1766 4.5127 11.1766 4.21973 11.4695C3.92676 11.7625 3.92676 12.2371 4.21973 12.5301L5.46973 13.7801C5.61035 13.9212 5.80176 13.9998 6.00001 13.9998C6.00685 13.9998 6.01269 13.9998 6.01954 13.9993C6.2256 13.9939 6.41994 13.9046 6.55763 13.7517L8.80763 11.2517C9.08497 10.9437 9.05957 10.4695 8.75197 10.1922C8.44435 9.91581 7.97072 9.94025 7.69238 10.2479ZM7.69238 5.24787L5.97169 7.16047L5.28029 6.46953C4.98732 6.17656 4.5127 6.17656 4.21973 6.46953C3.92676 6.7625 3.92676 7.23712 4.21973 7.53009L5.46973 8.78009C5.61035 8.92118 5.80176 8.99981 6.00001 8.99981C6.00685 8.99981 6.01269 8.99981 6.01954 8.99931C6.2256 8.99393 6.41994 8.90459 6.55763 8.75175L8.80763 6.25175C9.08497 5.94365 9.05957 5.46953 8.75197 5.19219C8.44435 4.91581 7.97072 4.94025 7.69238 5.24787ZM5.50019 15.4997C4.67176 15.4997 4.0002 16.1714 4.0002 16.9997C4.0002 17.8282 4.67176 18.4998 5.50019 18.4998C6.32863 18.4998 7.00019 17.8282 7.00019 16.9997C7.00019 16.1714 6.32863 15.4997 5.50019 15.4997ZM12 7.99981H19C19.5523 7.99981 20 7.55212 20 6.99981C20 6.4475 19.5523 5.99981 19 5.99981H12C11.4477 5.99981 11 6.4475 11 6.99981C11 7.55212 11.4477 7.99981 12 7.99981ZM19 10.9998H12C11.4477 10.9998 11 11.4475 11 11.9998C11 12.5521 11.4477 12.9998 12 12.9998H19C19.5523 12.9998 20 12.5521 20 11.9998C20 11.4475 19.5523 10.9998 19 10.9998ZM19 15.9998H10C9.44769 15.9998 9.00001 16.4475 9.00001 16.9998C9.00001 17.5521 9.44769 17.9998 10 17.9998H19C19.5523 17.9998 20 17.5521 20 16.9998C20 16.4475 19.5523 15.9998 19 15.9998Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CheckListSolid;