import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CalendarRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 6H15.75V4.75C15.75 4.33594 15.4141 4 15 4C14.5859 4 14.25 4.33594 14.25 4.75V6H9.75V4.75C9.75 4.33594 9.41406 4 9 4C8.58594 4 8.25 4.33594 8.25 4.75V6H7C5.89544 6 5 6.89538 5 8V18C5 19.1046 5.89544 20 7 20H17C18.1046 20 19 19.1046 19 18V8C19 6.89538 18.1046 6 17 6ZM17 18.5H7C6.72431 18.5 6.5 18.2758 6.5 18V10H17.5V18C17.5 18.2758 17.2757 18.5 17 18.5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CalendarRegular;