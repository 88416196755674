import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PenSquareSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.9719 18H5.97187V8.00029H10.85L12.85 6.00034H5.97187C4.89531 6.00034 4 6.89563 4 8.00029V18C4 19.1047 4.89531 20 5.97187 20H15.9438C17.0481 20 17.9156 19.1047 17.9156 18L17.9155 11.1221L15.9436 13.1217V18H15.9719ZM19.5594 5.3188L18.6803 4.43944C18.0944 3.85352 17.1447 3.85352 16.5591 4.43944L15.3522 5.6466L18.3525 8.64684L19.5594 7.43968C20.1469 6.85438 20.1469 5.90472 19.5594 5.3188ZM8.60313 12.5752L8.00812 15.5507C7.95569 15.8131 8.18703 16.0445 8.44938 15.992L11.4253 15.397C11.5705 15.3679 11.7038 15.2966 11.8084 15.1919L17.6459 9.35457L14.6456 6.35433L8.80625 12.1939C8.70312 12.2971 8.63125 12.4314 8.60313 12.5752Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PenSquareSolid;