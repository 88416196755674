import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ArrowLeftSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.96401 18.6984L4.53543 12.9841C4.27315 12.7084 4.14258 12.3546 4.14258 12.0003C4.14258 11.6459 4.27315 11.2921 4.53543 11.0165L9.96401 5.30217C10.5075 4.73017 11.4115 4.70674 11.9841 5.25028C12.5533 5.79435 12.5779 6.69949 12.0354 7.26981L8.89901 10.5717L18.714 10.5717C19.5031 10.5717 20.1426 11.2112 20.1426 12.0003C20.1426 12.7893 19.5031 13.4288 18.714 13.4288L8.89901 13.4288L12.0354 16.7307C12.5779 17.3016 12.5544 18.2067 11.9841 18.7503C11.4115 19.2938 10.5075 19.2703 9.96401 18.6984Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default ArrowLeftSolid;