import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CalendarSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5 6H16V5C16 4.45 15.55 4 15 4C14.45 4 14 4.45 14 5V6H10V5C10 4.45 9.55 4 9 4C8.45 4 8 4.45 8 5V6H6.5C5.67156 6 5 6.67156 5 7.5V9H19V7.5C19 6.67156 18.3284 6 17.5 6ZM5 18.5C5 19.3281 5.67188 20 6.5 20H17.5C18.3281 20 19 19.3281 19 18.5V10H5V18.5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CalendarSolid;