import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PersonFemaleRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.7499 7C12.5781 7 13.2499 6.32812 13.2499 5.5C13.2499 4.67188 12.5781 4 11.7781 4C10.9499 4 10.2781 4.67188 10.2781 5.5C10.2781 6.32812 10.9218 7 11.7499 7ZM16.3968 12.6187L14.4768 9.35313C13.9843 8.51875 13.0749 8 12.1062 8H11.3937C10.4249 8 9.51557 8.51875 9.02339 9.35625L7.1037 12.6187C6.89364 12.9781 7.01276 13.4094 7.37026 13.6469C7.48932 13.7156 7.61995 13.75 7.7487 13.75C8.0056 13.75 8.25589 13.6177 8.3962 13.3803L10.0734 10.5287L8.22182 16H9.74995V19.25C9.74995 19.6641 10.0856 20 10.4718 20C10.8581 20 11.2499 19.6656 11.2499 19.25V16H12.2499V19.25C12.2499 19.6641 12.5856 20 12.9999 20C13.4143 20 13.7499 19.6656 13.7499 19.25V16H15.2499L13.4262 10.5281L15.1034 13.3797C15.2437 13.6187 15.4937 13.75 15.7499 13.75C15.8789 13.75 16.0097 13.7168 16.1287 13.6465C16.4874 13.4094 16.6062 12.9781 16.3968 12.6187ZM10.3312 14.5L11.7499 10.2438L13.1687 14.5H10.3312Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PersonFemaleRegular;