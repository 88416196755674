import { Button, Typography, useTheme } from "@stardust-ds/react";
import { Logo } from "styles/global";
import { AccessCard } from "components/AccessWrapper/styles";
import useRouteHandler from "hooks/useRouteHandler";

interface IProps {
  title?: string;
  description?: string;
  goToLogin?: boolean;
  children?: JSX.Element | React.ReactNode;
}

const PublicSuccessMessage: React.FC<IProps> = ({title, description, goToLogin, children}) => {
  const theme = useTheme();
  const { to } = useRouteHandler();

  return (
    <AccessCard>
        <Logo src={require('assets/images/logo_mega_3.webp')}></Logo>
        <Typography data-title fontSize='lg' mTop="xl" textAlign="center" color={theme.neutrals.gray7}>
            {title}
        </Typography>
        <Typography data-title fontSize='xs' mTop="nano" textAlign="center" fontWeight="normal" mBottom='sm' color={theme.neutrals.gray7}>
            {description}
        </Typography>
        {
          goToLogin?
          <Button onClick={() => to('signin')} type='button' mBottom='xs' bgColor={theme.brand.color.primary.pure} isFullWidth mTop='sm'>
              Fazer Login
          </Button> :
          <></>
        }

        {children}
    </AccessCard>
  );
}

export default PublicSuccessMessage;