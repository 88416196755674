import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PenSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5627 6.31753L17.684 4.43944C17.0979 3.85352 16.1463 3.85352 15.5605 4.43944L13.7934 6.20659L17.7665 10.2065L19.5346 8.43936C20.1504 7.85312 20.1504 6.90345 19.5627 6.31753ZM13.0888 6.91345L4.97071 15.0289C4.90087 15.0987 4.85333 15.1876 4.83392 15.2844L4.01054 19.3999C3.93848 19.7497 4.24705 20.0591 4.59716 19.9904L8.71405 19.1672C8.81089 19.1479 8.89976 19.1003 8.96959 19.0306L17.0877 10.9151L13.0888 6.91345Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PenSolid;