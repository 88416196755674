import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CloseCircleRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.5031 9.46875C14.2102 9.17578 13.7356 9.17578 13.4425 9.46875L12 10.9406L10.5031 9.46875C10.2102 9.17578 9.73563 9.17578 9.4425 9.46875C9.14937 9.76172 9.14953 10.2362 9.4425 10.5294L10.9122 11.9991L9.46875 13.4688C9.17578 13.7617 9.17578 14.2362 9.46875 14.5294C9.76166 14.8223 10.2362 14.8225 10.5294 14.5294L12 13.0594L13.4697 14.5291C13.7626 14.822 14.2372 14.8222 14.5303 14.5291C14.8233 14.2361 14.8233 13.7616 14.5303 13.4684L13.0606 11.9988L14.5303 10.5291C14.8219 10.2375 14.8219 9.7625 14.5031 9.46875ZM12 4C7.58125 4 4 7.58125 4 12C4 16.4187 7.58125 20 12 20C16.4187 20 20 16.4187 20 12C20 7.58125 16.4187 4 12 4ZM12 18.5C8.41563 18.5 5.5 15.5841 5.5 12C5.5 8.41594 8.41563 5.5 12 5.5C15.5844 5.5 18.5 8.41594 18.5 12C18.5 15.5841 15.5844 18.5 12 18.5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CloseCircleRegular;