import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CloseCircleLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.3531 9.64687C14.1578 9.45156 13.8412 9.45156 13.6462 9.64687L12 11.2937L10.3531 9.64687C10.1578 9.45156 9.84125 9.45156 9.64625 9.64687C9.45125 9.84219 9.45094 10.1588 9.64625 10.3538L11.2937 12L9.64687 13.6469C9.45156 13.8422 9.45156 14.1588 9.64687 14.3538C9.84206 14.5489 10.1584 14.5492 10.3538 14.3538L12 12.7063L13.6466 14.3528C13.8418 14.548 14.1581 14.5483 14.3534 14.3528C14.5487 14.1575 14.5487 13.8409 14.3534 13.6459L12.7063 12L14.3528 10.3534C14.55 10.1594 14.55 9.84062 14.3531 9.64687ZM12 4C7.58125 4 4 7.58125 4 12C4 16.4187 7.58125 20 12 20C16.4187 20 20 16.4187 20 12C20 7.58125 16.4187 4 12 4ZM12 19C8.14062 19 5 15.8594 5 12C5 8.14062 8.14062 5 12 5C15.8594 5 19 8.14062 19 12C19 15.8594 15.8594 19 12 19Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CloseCircleLight;