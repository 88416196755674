import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ClockRotateLeftLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M12 4C16.4187 4 20 7.58125 20 12C20 16.4187 16.4187 20 12 20C9.38125 20 7.05844 18.7438 5.59969 16.8C5.43375 16.5781 5.47844 16.2656 5.69906 16.1C5.92 15.9344 6.23344 15.9781 6.39937 16.2C7.67812 17.9 9.70937 19 12 19C15.8656 19 19 15.8656 19 12C19 8.13438 15.8656 5 12 5C9.20937 5 6.79719 6.63438 5.67344 9H8.5C8.775 9 9 9.225 9 9.5C9 9.775 8.775 10 8.5 10H4.5C4.22387 10 4 9.775 4 9.5V5.5C4 5.22375 4.22387 5 4.5 5C4.77625 5 5 5.22375 5 5.5V8.09688C6.36438 5.66531 8.9875 4 12 4ZM12 8C12.275 8 12.5 8.225 12.5 8.5V11.7937L14.8531 14.1469C15.05 14.3406 15.05 14.6594 14.8531 14.8531C14.6594 15.05 14.3406 15.05 14.1469 14.8531L11.6469 12.3531C11.5531 12.2594 11.4719 12.1313 11.4719 12V8.5C11.4719 8.225 11.725 8 11.9719 8H12Z" fill="#3B454F"/>
      </svg>
    </IconWrapper>
  );
}

export default ClockRotateLeftLight;