import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CalendarLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 6H16V4.5C16 4.22384 15.7762 4 15.5 4C15.2238 4 15 4.22384 15 4.5V6H9V4.5C9 4.22384 8.77616 4 8.5 4C8.22384 4 8 4.22384 8 4.5V6H7C5.89544 6 5 6.89544 5 8V18C5 19.1046 5.89544 20 7 20H17C18.1046 20 19 19.1046 19 18V8C19 6.89544 18.1046 6 17 6ZM7 7H17C17.5523 7 18 7.44772 18 8V9H6V8C6 7.44772 6.44772 7 7 7ZM17 19H7C6.44772 19 6 18.5523 6 18V10H18V18C18 18.5523 17.5523 19 17 19Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CalendarLight;