import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const EyeSlashLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_346_11181)">
          <path d="M10.8215 9.43061L11.5943 10.0408C11.7269 10.018 11.8611 9.99976 12 9.99976C13.2694 9.99976 14.3011 10.9933 14.3841 12.2433L15.1546 12.8516C15.1757 12.7031 15.2 12.5554 15.2 12.4011V12.3998C15.2 10.6335 13.7667 9.19976 12 9.19976C11.5829 9.19976 11.187 9.28531 10.8215 9.43061ZM12 17.1998C9.3371 17.1998 6.89212 15.4003 5.62437 12.516C5.6145 12.489 5.60127 12.4232 5.5999 12.405C5.60127 12.3763 5.61455 12.3104 5.61915 12.2959C5.94017 11.5653 6.33652 10.9056 6.79135 10.3258L6.16502 9.83141C5.66347 10.4695 5.23165 11.1891 4.88672 11.9741C4.83905 12.0848 4.8 12.2758 4.8 12.3998C4.8 12.5242 4.83905 12.7147 4.88672 12.8254C6.24297 15.9116 8.92655 17.9998 12 17.9998C13.2033 17.9998 14.3451 17.6757 15.3728 17.1008L14.6793 16.5533C13.8393 16.9665 12.935 17.1998 12 17.1998ZM12 7.59976C14.6629 7.59976 17.1079 9.39924 18.3756 12.2835C18.3855 12.3105 18.3987 12.3763 18.4001 12.3945C18.3987 12.4232 18.3855 12.4891 18.3809 12.5036C18.0599 13.2341 17.6637 13.8938 17.2089 14.4734L17.8351 14.9679C18.3366 14.3298 18.7684 13.6103 19.1133 12.8254C19.1609 12.7147 19.2 12.5237 19.2 12.3998C19.2 12.2753 19.1609 12.0848 19.1133 11.9741C17.757 8.88789 15.0734 6.79976 12 6.79976C10.7969 6.79976 9.65508 7.12374 8.62752 7.69849L9.32115 8.24611C10.161 7.83296 11.0651 7.59976 12 7.59976ZM12.0014 15.5998C12.4183 15.5998 12.814 15.5143 13.1793 15.369L12.4062 14.7587C12.2735 14.7815 12.1391 14.7998 12 14.7998C10.73 14.7998 9.69785 13.8053 9.61578 12.5557L8.8456 11.9477C8.82433 12.0966 8.8 12.2449 8.8 12.3998C8.8 14.1661 10.2334 15.5998 12 15.5998H12.0014ZM19.8477 18.0857L4.6476 6.08526C4.47495 5.94894 4.22337 5.97901 4.08587 6.15129C3.94917 6.32471 3.97885 6.57631 4.1523 6.71344L19.3524 18.7138C19.4258 18.7716 19.5133 18.7998 19.6001 18.7998C19.718 18.7998 19.8352 18.7474 19.9141 18.6478C20.0508 18.4744 20.0212 18.2228 19.8477 18.0857Z" fill="currentColor"/>
        </g>
        <defs>
          <clipPath id="clip0_346_11181">
            <rect width="16" height="12.8" fill="white" transform="translate(4 6)"/>
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
}

export default EyeSlashLight;