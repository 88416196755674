import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const EyeLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 8.55558C10.0371 8.55558 8.44444 10.1486 8.44444 12.1111C8.44444 14.0737 10.0371 15.6667 12 15.6667H12.0016C13.9637 15.6667 15.5556 14.0745 15.5556 12.1127V12.1111C15.5556 10.1486 13.9629 8.55558 12 8.55558ZM12 14.7778C10.5296 14.7778 9.33333 13.5816 9.33333 12.1111C9.33333 10.6407 10.5296 9.44447 12 9.44447C13.4704 9.44447 14.6667 10.6407 14.6667 12.1127C14.6667 13.5822 13.4711 14.7778 12 14.7778ZM19.9036 11.6382C18.3967 8.20905 15.4149 5.88892 12 5.88892C8.58506 5.88892 5.60244 8.21058 4.09636 11.6382C4.04339 11.7612 4 11.9734 4 12.1111C4 12.2494 4.04339 12.461 4.09636 12.5841C5.60331 16.0132 8.58506 18.3334 12 18.3334C15.4149 18.3334 18.3976 16.0116 19.9036 12.5841C19.9566 12.461 20 12.2488 20 12.1111C20 11.9729 19.9566 11.7612 19.9036 11.6382ZM19.0898 12.2265C17.6756 15.4451 14.9589 17.4445 12 17.4445C9.04122 17.4445 6.32461 15.445 4.91597 12.2403C4.905 12.2103 4.89031 12.1372 4.88878 12.1169C4.89031 12.0851 4.90506 12.0119 4.91017 11.9957C6.32439 8.77722 9.04108 6.7778 12 6.7778C14.9588 6.7778 17.6754 8.77722 19.084 11.9819C19.095 12.0119 19.1097 12.0851 19.1112 12.1053C19.1097 12.1371 19.0949 12.2104 19.0898 12.2265Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default EyeLight;