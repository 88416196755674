export const sizeToPixel = (size?: string | number, def = 24) =>
  size ? (typeof size === "string" ? size : size + 7 + "px") : def;

export const toggleClassName = (opt: {
  if: boolean;
  default?: string;
  ifClass: string;
  elseIf?: boolean;
  elseIfClass?: string;
  elseClass?: string;
}): string => {
  let className: string[] = [];
  if (opt.default) className.push(opt.default);

  if (opt.if) className.push(opt.ifClass);
  else if (opt.elseIf && opt.elseIfClass) className.push(opt.elseIfClass);
  else if (opt.elseClass) className.push(opt.elseClass);

  return className.join(" ");
};

export const hexToRGB = (color: string) => {
  let arr = color.replace("#", "").split("");

  const r = arr[0] + arr[1];
  const g = arr[2] + arr[3];
  const b = arr[4] + arr[5];

  return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
};

export const hexToRGBA = (color: string, alpha: number) => {
  let [r, g, b] = hexToRGB(color);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
