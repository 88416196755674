import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const LinkLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3598 16.1171L9.23861 17.2388C8.2244 18.2522 6.57371 18.2522 5.56125 17.2388C4.54779 16.2248 4.54779 14.5739 5.56125 13.5614L8.76113 10.3615C9.77534 9.34809 11.426 9.34809 12.4385 10.3615C13.3487 11.2723 13.4527 12.7335 12.6807 13.7389C12.5479 13.9155 12.5834 14.1664 12.76 14.2989C12.9362 14.4317 13.1875 14.3965 13.32 14.2195C14.3302 12.8763 14.1947 10.9647 13.0042 9.77472C11.6783 8.44877 9.52187 8.44952 8.19442 9.77472L4.99427 12.9947C3.66858 14.3206 3.66858 16.4771 4.99427 17.8045C5.65725 18.4675 6.53071 18.7962 7.40168 18.7962C8.27265 18.7962 9.14086 18.467 9.80384 17.8045L10.925 16.6828C11.0813 16.5265 11.0813 16.2733 10.925 16.1171C10.7698 15.9621 10.5148 15.9621 10.3598 16.1171ZM19.0045 6.99418C17.6785 5.66823 15.5221 5.66898 14.1947 6.99418L13.0735 8.11589C12.9172 8.27213 12.9172 8.52537 13.0735 8.68161C13.2297 8.83786 13.4829 8.83786 13.6392 8.68161L14.7604 7.55991C15.7746 6.54645 17.4253 6.54645 18.4377 7.55991C19.4512 8.57387 19.4512 10.2248 18.4377 11.2373L15.2379 14.4371C14.2237 15.4506 12.573 15.4506 11.5605 14.4371C10.6498 13.5272 10.5473 12.0672 11.3198 11.0398C11.4526 10.8632 11.4171 10.6123 11.2405 10.4798C11.0623 10.3473 10.8123 10.3823 10.6798 10.5598C9.66984 11.9022 9.80484 13.8147 10.9948 15.0046C11.6578 15.6676 12.5287 15.9991 13.3994 15.9991C14.2702 15.9991 15.1411 15.6673 15.8041 15.0046L19.004 11.8047C20.3319 10.4773 20.3319 8.32038 19.0045 6.99418Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default LinkLight;