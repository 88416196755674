import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ChevronRightLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.18399 19.8487C8.0612 19.7366 7.99981 19.5831 7.99981 19.4291C7.99981 19.2901 8.05006 19.1512 8.15159 19.0413L14.6505 12.0005L8.15159 4.95971C7.93731 4.72756 7.9507 4.36542 8.18399 4.15224C8.41613 3.93795 8.7766 3.95245 8.99088 4.18406L15.8481 11.6127C16.0512 11.8325 16.0512 12.1685 15.8481 12.3883L8.99088 19.8169C8.7766 20.0485 8.41613 20.063 8.18399 19.8487Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default ChevronRightLight;