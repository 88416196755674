import styled, { css } from "styled-components";

export const Container = styled.div`
  overflow: hidden;
  display: grid;
  height: 100%;
  justify-items: baseline;
  grid-template-columns: auto auto;
  height: 100vh;
  .paper {
      width: 346px;
      height: 100%;
    }
  ${({ theme }) => css`
    @media screen and (max-width: ${theme.brand.media_query.tablet}) {
      .paper {
        width: 346px;
        padding: ${`${theme.spacing.xs}px ${theme.spacing.xxs}px`};
      }
    }
    @media screen and (max-width: ${theme.brand.media_query.mobile}) {
      display: flow;
      background-color: ${theme.neutrals.pureWhite};
      .paper {
        width: 100%;
        height: 100%;
        box-shadow: unset;
        padding: ${`${theme.spacing.xxxs}px ${theme.spacing.xxs}px`};
      }
      .bg-logo{
        display: none;
      }
    }
    `}
  .mt-md {
    margin-top: 10vh;
  }
  .grid-item {
    .back-btn {
      border: none;
      background: none;
      color: transparent;
      position: fixed;
      left: 50px;
      top: 50px;
      svg{
        cursor: pointer;
      }
    }
    .img-inside {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const AccessCard = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme  }) => css`
    background-color: ${theme.neutrals.pureWhite};
    padding: ${theme.spacing.xxs}px;
    @media screen and (max-width: ${theme.brand.media_query.mobile}) {
      margin: auto;
    }
  `}
`;

