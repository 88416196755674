import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const RoundAlert: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9999 16.2666V11.9999M11.9999 7.73325H12.0106M22.6666 11.9999C22.6666 17.891 17.891 22.6666 11.9999 22.6666C6.10888 22.6666 1.33325 17.891 1.33325 11.9999C1.33325 6.10888 6.10888 1.33325 11.9999 1.33325C17.891 1.33325 22.6666 6.10888 22.6666 11.9999Z" stroke="#FFAE00" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </IconWrapper>
  );
}

export default RoundAlert;