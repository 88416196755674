import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const TrashCanRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5 6.5H15.9258L14.8633 4.72656C14.5933 4.27584 14.1064 4 13.581 4H10.419C9.89359 4 9.40675 4.27584 9.13672 4.72656L8.07422 6.5H5.5C5.22266 6.5 5 6.72266 5 7V7.5C5 7.77734 5.22266 8 5.5 8H6V18C6 19.1046 6.89544 20 8 20H16C17.1046 20 18 19.1046 18 18V8H18.5C18.7773 8 19 7.77734 19 7.5V7C19 6.72266 18.7773 6.5 18.5 6.5ZM10.3711 5.58984C10.4023 5.53516 10.4648 5.5 10.5312 5.5H13.4688C13.5352 5.5 13.5977 5.53516 13.6289 5.58984L14.1758 6.5H9.82422L10.3711 5.58984ZM16 18.5H8C7.72384 18.5 7.5 18.2762 7.5 18V8H16.5V18C16.5 18.2762 16.2762 18.5 16 18.5ZM12 17C12.2764 17 12.5 16.7764 12.5 16.5V10C12.5 9.72363 12.2764 9.5 12 9.5C11.7236 9.5 11.5 9.72363 11.5 10V16.5C11.5 16.7764 11.7236 17 12 17ZM9.5 17C9.77637 17 10 16.7764 10 16.5V10C10 9.72363 9.77637 9.5 9.5 9.5C9.22363 9.5 9 9.72363 9 10V16.5C9 16.7764 9.22363 17 9.5 17ZM14.5 17C14.7764 17 15 16.7764 15 16.5V10C15 9.72363 14.7764 9.5 14.5 9.5C14.2236 9.5 14 9.72363 14 10V16.5C14 16.7764 14.2236 17 14.5 17Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default TrashCanRegular;