import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const AccountValueRed: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.59267 10.2221L9.96304 12.5925L15.2964 7.25918M20.6297 22.6666V7.02214C20.6297 5.03084 20.6297 4.03519 20.2422 3.27462C19.9013 2.6056 19.3574 2.06167 18.6883 1.72078C17.9278 1.33325 16.9321 1.33325 14.9408 1.33325H7.35564C5.36434 1.33325 4.36869 1.33325 3.60812 1.72078C2.93909 2.06167 2.39516 2.6056 2.05428 3.27462C1.66675 4.03519 1.66675 5.03084 1.66675 7.02214V22.6666L4.92601 20.2962L7.88897 22.6666L11.1482 20.2962L14.4075 22.6666L17.3705 20.2962L20.6297 22.6666Z" stroke="#EE4953" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </IconWrapper>
  );
}

export default AccountValueRed;