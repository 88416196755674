import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const UploadRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.2121 13.5H14.1515V15H18.2121C18.492 15 18.7197 15.2243 18.7197 15.5V18C18.7197 18.2757 18.492 18.5 18.2121 18.5H6.0303C5.75044 18.5 5.52273 18.2757 5.52273 18V15.5C5.52273 15.225 5.7505 15 6.0303 15H10.0909V13.5H6.0303C4.90888 13.5 4 14.3953 4 15.5V18C4 19.1047 4.90888 20 6.0303 20H18.2121C19.3335 20 20.2424 19.1047 20.2424 18V15.5C20.2424 14.3969 19.332 13.5 18.2121 13.5ZM8.31757 9.50317L11.3598 6.56068V15C11.3598 15.4141 11.7009 15.75 12.1212 15.75C12.5415 15.75 12.8826 15.4141 12.8826 15V6.56068L15.8973 9.53036C16.0454 9.67817 16.2389 9.75004 16.4356 9.75004C16.6323 9.75004 16.8252 9.6768 16.974 9.53033C17.2714 9.23736 17.2714 8.76283 16.974 8.46971L12.6596 4.21973C12.3622 3.92676 11.8804 3.92676 11.5829 4.21973L7.26847 8.46971C6.97106 8.76267 6.97106 9.2372 7.26847 9.53033C7.56588 9.82345 8.04792 9.82192 8.31757 9.50317ZM17.7045 16.75C17.7045 16.3356 17.3635 16 16.9432 16C16.5228 16 16.1818 16.3344 16.1818 16.75C16.1818 17.1641 16.5228 17.5 16.9432 17.5C17.3635 17.5 17.7045 17.1656 17.7045 16.75Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default UploadRegular;