import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CheckListLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.50587 17C4.95825 17 4.5 17.4492 4.5 18C4.5 18.5508 4.95825 19 5.50587 19C6.05731 19 6.5 18.5508 6.5 18C6.5 17.4492 6.05731 17 5.50587 17ZM10.5 8.5H19.5C19.775 8.5 20 8.27497 20 8C20 7.72497 19.775 7.5 19.5 7.5H10.5C10.225 7.5 10 7.72497 10 8C10 8.27497 10.225 8.5 10.5 8.5ZM19.5 12.5H10.5C10.225 12.5 10 12.725 10 13C10 13.275 10.225 13.5 10.5 13.5H19.5C19.775 13.5 20 13.275 20 13C20 12.725 19.775 12.5 19.5 12.5ZM19.5 17.5H9.5C9.22503 17.5 9 17.725 9 18C9 18.275 9.22503 18.5 9.5 18.5H19.5C19.775 18.5 20 18.275 20 18C20 17.725 19.775 17.5 19.5 17.5ZM8.11631 11.1793L5.9355 13.7958L4.82019 12.8655C4.60925 12.6907 4.29378 12.7171 4.11606 12.9295C3.93928 13.1419 3.96759 13.4574 4.17953 13.6342L5.67962 14.8843C5.77337 14.9619 5.88669 15 5.99997 15C6.14256 15 6.28516 14.939 6.38378 14.8203L8.88394 11.82C9.06072 11.6076 9.03241 11.2921 8.82047 11.1154C8.60953 10.9405 8.29309 10.9664 8.11631 11.1793ZM8.11631 6.17919L5.9355 8.79575L4.82019 7.86544C4.60925 7.69063 4.29378 7.71697 4.11606 7.92941C3.93928 8.14184 3.96759 8.45731 4.17953 8.63409L5.67962 9.88425C5.77337 9.96191 5.88669 10 5.99997 10C6.14256 10 6.28516 9.93897 6.38378 9.82028L8.88394 6.81991C9.06072 6.60747 9.03241 6.292 8.82047 6.11522C8.60953 5.94038 8.29309 5.96675 8.11631 6.17919Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CheckListLight;