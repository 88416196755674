import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PersonFemaleSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 7C12.8281 7 13.5 6.32812 13.5 5.5C13.5 4.67188 12.8281 4 12.0281 4C11.2 4 10.5281 4.67188 10.5281 5.5C10.5281 6.32812 11.1719 7 12 7ZM16.8469 12.4688L14.9347 9.40781C14.3844 8.52813 13.4313 8 12.3906 8H11.6094C10.5688 8 9.61719 8.52813 9.06438 9.40938L7.15229 12.4688C6.85932 12.9375 7.0019 13.5563 7.46969 13.8469C7.93938 14.1398 8.55563 13.9973 8.84751 13.5284L9.86688 11.8975L8.47188 16H9.72188V19C9.72188 19.5522 10.1697 20 10.7219 20C11.2741 20 11.6938 19.5522 11.6938 19V16H12.1938V19C12.1938 19.5522 12.6416 20 13.1938 20C13.7459 20 14.1938 19.5522 14.1938 19V16H15.4438L14.0763 11.8969L15.0959 13.5288C15.3406 13.8344 15.6688 14 16 14C16.1807 14 16.3644 13.9512 16.5294 13.8482C16.9969 13.5562 17.1406 12.9375 16.8469 12.4688Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PersonFemaleSolid;
