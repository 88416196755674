import styled, {css} from 'styled-components';

export const NavbarWrapper = styled.nav`
  position: relative;
  max-height: 75px;
  background-color: #FFF;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow:0px 1px 5px #0d0d0d;
  -moz-box-shadow:0px 1px 5px #0d0d0d;
  box-shadow:0px 1px 10px #0d0d0d;
  @media (max-width: 768px) {
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    max-height: 300px;
  }
`;

export const Logo = styled.div`
  max-height: 75px;
  .logo-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 130px;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const NavLinks = styled.div<{isActive: boolean}>`
${({ isActive }) => css`
  display: ${isActive? 'flex':'none'};
  @media (max-width: 768px) {
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`  
}
`;

export const NavBtns = styled.div`
  display: flex;
  div {
    display: flex;
    text-align: center;
    align-items: center;
    flex: 50%
    width: 100px;
    margin: 10px;
    button{
      border-radius: 25px;
      height: 30px;
    }
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    flex-direction: column;
    top: 82px;
  }
`;

export const NavLink = styled.a`
  color: black;
  margin-left: 15px;
  cursor: pointer;
  
  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 10px;
  }
`;

export const ToggleButton = styled.div`
  display: none;
  cursor: pointer;
  font-size: 1.5em;
  position: fixed;
  right: 20px;
  @media (max-width: 768px) {
    display: flex;
    position: fixed;
    right: 20px;
  }
`;