import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CarretSmallDownSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.6706 10.0518L12.8944 14.8618C12.6276 15.0985 12.3194 15.215 12.0113 15.215C11.7031 15.215 11.3958 15.0976 11.1609 14.8627L6.3847 10.0527C6.00721 9.70981 5.90423 9.19123 6.09039 8.74405C6.27655 8.29687 6.71539 8 7.20128 8H16.7875C17.2737 8 17.7126 8.2924 17.899 8.74217C18.0854 9.19194 18.0163 9.70981 17.6706 10.0518Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CarretSmallDownSolid;