import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const FilterListRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.75 1.75H15.25C15.6641 1.75 16 1.41406 16 1.02812C16 0.642187 15.6656 0.25 15.25 0.25H13.75C13.3375 0.25 13 0.585937 13 0.971874C13 1.35781 13.3375 1.75 13.75 1.75ZM15.25 5.24999H10.75C10.3375 5.24999 10 5.58749 10 5.99999C10 6.41249 10.3359 6.74999 10.75 6.74999H15.25C15.6656 6.74999 16 6.41562 16 5.99999C16 5.58437 15.6656 5.24999 15.25 5.24999ZM10.2969 0H0.702519C0.111895 0 -0.214042 0.671874 0.158458 1.12125L3.50002 5.05624V9.49999C3.50002 9.74461 3.61914 9.97405 3.82033 10.1144L6.2922 11.8637C6.45314 11.9562 6.60314 12 6.74689 12C7.13439 12 7.50001 11.6937 7.50001 11.2219V5.05624L10.8406 1.12187C11.2125 0.671874 10.8875 0 10.2969 0ZM6.35626 4.08437L6.00001 4.50624V9.80936L5.00001 9.10968V4.50624L2.44814 1.5H8.55126L6.35626 4.08437ZM15.25 10.25H10.75C10.3375 10.25 10 10.5875 10 11C10 11.4125 10.3359 11.75 10.75 11.75H15.25C15.6641 11.75 16 11.414 16 11C16 10.5859 15.6656 10.25 15.25 10.25Z" fill="white"/>
        </svg>
    </IconWrapper>
  );
}

export default FilterListRegular;