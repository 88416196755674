import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CarretSmallUpLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.21517 15L16.7975 15C17.8632 15 18.403 13.7041 17.6472 12.9479L12.8729 8.13984C12.4034 7.6703 11.6427 7.6703 11.1732 8.13984L6.39893 12.9479C5.57742 13.7041 6.11082 15 7.21517 15ZM12.0233 8.9899L16.7972 13.798L7.18475 13.798L12.0233 8.9899Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CarretSmallUpLight;