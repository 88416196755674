import React, { useCallback, useState } from 'react';

import { useTheme, Button } from '@stardust-ds/react';

import { NavbarWrapper, Logo, ToggleButton, NavBtns} from './styles';
import AvatarMenu from 'components/Avatar';

interface IProps {
  setShowUpdatePasswordModal: (showUpdatePasswordModal: boolean) => void;
  children: JSX.Element | React.ReactNode;
  displayNav?: string;
}

const ClientWrapper: React.FC<IProps> = ({ children, setShowUpdatePasswordModal }) => {
  const theme = useTheme();
  const [active, setActive] = useState(false);
  
  const handleActive = () => {
    setActive(!active)
  };

  return (
    <>
    <NavbarWrapper>
      <Logo>
        <div className='logo-container'>
          <img src={require('assets/images/logo_mega_3.webp')} />
        </div>
      </Logo>
      {/* <NavItems isActive={active} /> */}
      <NavBtns>
        {/* <div>
          <Button bgColor={theme.brand.color.secondary.pure}>FALE AGORA</Button>
        </div> */}
        <div>
          <AvatarMenu setShowUpdatePasswordModal={setShowUpdatePasswordModal}/>
        </div>
      </NavBtns>
      <ToggleButton onClick={handleActive}>&#9776;</ToggleButton>
    </NavbarWrapper>
      {children}
    </>
  );
}

export default ClientWrapper;