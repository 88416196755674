import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PlusRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 12C20 12.5096 19.5865 12.9231 19.0769 12.9231H12.9231V19.0769C12.9231 19.5865 12.5096 20 12 20C11.4904 20 11.0769 19.5865 11.0769 19.0769V12.9231H4.92308C4.41346 12.9231 4 12.5096 4 12C4 11.4904 4.41346 11.0769 4.92308 11.0769H11.0769V4.92308C11.0769 4.41346 11.4904 4 12 4C12.5096 4 12.9231 4.41346 12.9231 4.92308V11.0769H19.0769C19.5865 11.0769 20 11.4904 20 12Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PlusRegular;