import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PersonFemaleLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.4469 11.775C14.3229 11.5274 14.0241 11.4284 13.7759 11.5514C13.5289 11.6749 13.4294 11.9751 13.5523 12.2223L15.1908 15.4973H9.80875L11.4472 12.2223C11.5702 11.9753 11.4706 11.6748 11.2236 11.5514C10.9781 11.4313 10.6781 11.5031 10.5528 11.775L8.58094 15.775C8.50378 15.9298 8.51159 16.1137 8.60242 16.2612C8.6942 16.4087 8.85533 16.4986 9.02805 16.4986H10.4999V19.4986C10.5 19.775 10.725 20 11 20C11.275 20 11.4719 19.7764 11.4719 19.5V16.5H13.4437V19.5C13.4719 19.775 13.725 20 13.9719 20C14.2188 20 14.4437 19.7764 14.4437 19.5V16.5H15.9156C16.0885 16.5 16.2497 16.4102 16.3413 16.2627C16.4321 16.1152 16.4399 15.9311 16.3627 15.7764L14.4469 11.775ZM16.9094 12.7563L14.9809 9.28438C14.5406 8.49094 13.7028 7.99813 12.7956 7.99813H12.1781C11.2709 7.99813 10.4331 8.49094 9.99281 9.28438L8.06281 12.7563C7.92903 12.9975 8.01594 13.3016 8.25712 13.4359C8.49537 13.5688 8.80212 13.4843 8.93775 13.2416L10.8662 9.76972C11.1313 9.29688 11.6312 8.97188 12.1781 8.97188H12.8247C13.3697 8.97188 13.8716 9.26728 14.135 9.74344L16.0634 13.2153C16.1552 13.3799 16.3268 13.4727 16.5016 13.4727C16.5846 13.4727 16.6669 13.4526 16.7441 13.4097C16.9844 13.3031 17.0719 12.9719 16.9094 12.7563ZM12.5 7.47187C13.4647 7.47187 14.2219 6.68656 14.2219 5.72187C14.2219 4.75719 13.4369 4 12.5 4C11.5631 4 10.75 4.785 10.75 5.72187C10.75 6.65875 11.5344 7.47187 12.5 7.47187ZM12.5 4.97188C12.9131 4.97188 13.2219 5.30844 13.2219 5.72187C13.2219 6.13531 12.9125 6.47187 12.5 6.47187C12.0875 6.47187 11.75 6.16344 11.75 5.72187C11.75 5.28031 12.0875 4.97188 12.5 4.97188Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PersonFemaleLight;