import styled from 'styled-components';

export const AvatarMenuContainer = styled.div`
  position: relative;
`;

export const AvatarIcon = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: #E9EBEE;
  border-radius: 20px;
  align-items: center;
`;

export const Menu = styled.div`
  position: absolute;
  top: 56px;
  right: 0;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 8px, 0px, 8px, 0px;
  min-width: 200px;
  border: 1px solid #CCD1D6;

  @media (max-width: 768px) {
    left: 0;
    right: 0;
  }
`;

export const MenuItem = styled.div`
  padding: 8px, 0px, 8px, 0px;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  min-width: 200px;

  &:hover {
    background-color: #f1f1f1;
  }

  & > svg {
    margin-right: 10px;
  }
`;