import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CarretSmallDownRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.1794 8H7.80535C6.20465 8 5.39285 9.94389 6.5332 11.0752L10.7349 15.2807C11.4344 15.9851 12.5797 15.9851 13.2837 15.2807L17.4891 11.0752C18.5826 9.94502 17.8166 8 16.1794 8ZM11.974 14.0078L7.80084 9.80233H16.1794L11.974 14.0078Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CarretSmallDownRegular;