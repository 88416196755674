import React, { useCallback, useMemo } from 'react';
import { Input as StartdustInput, StardustTheme, useTheme } from '@stardust-ds/react';
import { IconCon } from './styles';
import useToggle from 'hooks/useToggle';
import Icon from 'assets/icons/Icon';
import VMasker from 'vanilla-masker';

type TProps = React.ComponentProps<typeof StartdustInput>;

interface IProps extends TProps {
  isPass?: boolean;
  error?: string;
  labelColor?: string;
  mask?: string;
  multiMask?: (val: string) => string;
}

const HiddenIcon = ({ theme, hasError, isHidden }: { theme: StardustTheme, hasError: boolean, isHidden: boolean }) => {
  return (
    <IconCon hasError={hasError}>
      <Icon
        icon={isHidden ? 'eye-light' : 'eye_slash-light'}
        size={theme.fontSize.xs}
      />
    </IconCon>
  )
}

const Input: React.FC<IProps> = ({isPass = false, type, labelColor, error, onChange, mask, multiMask, ...props}) => {
  const theme = useTheme();
  const [isHidden, setIsHidden] = useToggle(true);
  const hasError = useMemo(() => !!error, [error]);
  const helperText = useMemo(() => error || props.helperText, [error, props.helperText]);
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if(mask) {
      e.target.value = VMasker.toPattern(e.target.value, mask);
    }
    else if(multiMask) {
      e.target.value = VMasker.toPattern(e.target.value, multiMask(e.target.value));
    }
    onChange?.(e);
  }, [mask, multiMask, onChange])

  return (
    <StartdustInput
      labelTypographyProps={{
        fontSize: 'xxs',
        color: labelColor || theme.brand.color.primary.dark,
        fontWeight: 'bold'
      }}
      helperTypographyProps={{
        fontSize: 'xxxs',
        fontWeight: 'bold',
      }}
      hasError={hasError}
      isFullWidth
      iconRight={isPass ? <HiddenIcon isHidden={isHidden} hasError={hasError} theme={theme} /> : undefined}
      type={!isPass ? type : isHidden ? 'password' : 'text'}
      iconRightAction={setIsHidden}
      maxLength={5000}
      {...props}
      helperText={helperText}
      onChange={handleChange}
    />
  );
}

export default Input;