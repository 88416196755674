import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CloseCircleSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4C7.58125 4 4 7.58125 4 12C4 16.4187 7.58125 20 12 20C16.4187 20 20 16.4187 20 12C20 7.58125 16.4187 4 12 4ZM14.5031 13.4688C14.7961 13.7617 14.7961 14.2362 14.5031 14.5294C14.21 14.8225 13.7356 14.8223 13.4425 14.5294L12 13.0594L10.5303 14.5291C10.2372 14.8222 9.76281 14.822 9.46969 14.5291C9.17672 14.2361 9.17672 13.7616 9.46969 13.4684L10.9394 11.9988L9.46875 10.5031C9.17578 10.2102 9.17578 9.73563 9.46875 9.4425C9.76172 9.14937 10.2362 9.14953 10.5294 9.4425L12 10.9406L13.4697 9.47094C13.7627 9.17797 14.2372 9.17797 14.5303 9.47094C14.8234 9.76391 14.8233 10.2384 14.5303 10.5316L13.0606 12.0013L14.5031 13.4688Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CloseCircleSolid;