import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PenRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.238 4.58594C17.8474 4.19537 17.335 4 16.8257 4C16.3139 4 15.8022 4.19531 15.4116 4.58594L5.40939 14.5906C5.14169 14.8583 4.95541 15.1966 4.87262 15.5659L4.00996 19.4034C3.94685 19.6906 4.1924 20 4.49831 20C4.53102 20 4.56442 19.9968 4.59823 19.99C4.59823 19.99 7.2418 19.4291 8.43502 19.1484C8.79558 19.0635 9.11833 18.8821 9.38016 18.6203L19.4158 8.58281C20.1966 7.80187 20.1938 6.53843 19.413 5.75749L18.238 4.58594ZM8.31792 17.5594C8.25184 17.6255 8.17722 17.6676 8.08958 17.6882C7.52188 17.822 6.60673 18.0229 5.81844 18.1938L6.33553 15.8945C6.35634 15.802 6.40271 15.7178 6.46976 15.6506L14.0277 8.09125L15.9065 9.97031L8.31792 17.5594Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PenRegular;