import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const TrashCanLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5 6H15.5L14.4492 4.60156C14.168 4.22266 13.7227 4 13.25 4H10.75C10.2773 4 9.83203 4.22266 9.55078 4.60156L8.5 6H5.5C5.22503 6 5 6.22497 5 6.5C5 6.77497 5.22503 7 5.5 7H18.5C18.775 7 19 6.77497 19 6.5C19 6.22497 18.775 6 18.5 6ZM9.75 6L10.3516 5.19922C10.4453 5.07422 10.5938 5 10.75 5H13.25C13.4062 5 13.5547 5.07422 13.6484 5.19922L14.25 6H9.75ZM17.5 8C17.2236 8 17 8.22363 17 8.5V17.5C17 18.3272 16.3272 19 15.5 19H8.5C7.67284 19 7 18.3272 7 17.5V8.5C7 8.22363 6.77638 8 6.5 8C6.22362 8 6 8.22363 6 8.5V17.5C6 18.8784 7.12159 20 8.5 20H15.5C16.8784 20 18 18.8784 18 17.5V8.5C18 8.22363 17.7764 8 17.5 8ZM9.5 17V10C9.5 9.72363 9.27637 9.5 9 9.5C8.72362 9.5 8.5 9.72363 8.5 10V17C8.5 17.2764 8.72362 17.5 9 17.5C9.27637 17.5 9.5 17.2764 9.5 17ZM12.5 17V10C12.5 9.72363 12.2764 9.5 12 9.5C11.7236 9.5 11.5 9.72363 11.5 10V17C11.5 17.2764 11.7236 17.5 12 17.5C12.2764 17.5 12.5 17.2764 12.5 17ZM15.5 17V10C15.5 9.72363 15.2764 9.5 15 9.5C14.7236 9.5 14.5 9.72363 14.5 10V17C14.5 17.2764 14.7236 17.5 15 17.5C15.2764 17.5 15.5 17.2764 15.5 17Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default TrashCanLight;