import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PersonMaleRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.3968 12.6187L14.4768 9.35313C13.9843 8.51875 13.0749 8 12.1062 8H11.3937C10.4249 8 9.51557 8.51875 9.02339 9.35625L7.1037 12.6187C6.89364 12.9781 7.01276 13.4094 7.37026 13.6469C7.48901 13.7156 7.61964 13.75 7.7487 13.75C8.00579 13.75 8.25682 13.6177 8.39651 13.3803L9.74995 11.0781V19.25C9.74995 19.6641 10.0856 20 10.4718 20C10.8581 20 11.2499 19.6656 11.2499 19.25V15H12.2499V19.25C12.2499 19.6641 12.5856 20 12.9999 20C13.4143 20 13.7499 19.6656 13.7499 19.25V11.0781L15.1034 13.3794C15.2437 13.6188 15.4937 13.75 15.7499 13.75C15.8789 13.75 16.0097 13.7168 16.1287 13.6465C16.4874 13.4094 16.6062 12.9781 16.3968 12.6187ZM12.2499 13.5H11.2499V9.50938C11.2968 9.50313 11.3468 9.5 11.3937 9.5H12.1065C12.1281 9.5 12.2031 9.50313 12.2499 9.50938V13.5ZM11.7499 7C12.5781 7 13.2499 6.32812 13.2499 5.5C13.2499 4.67188 12.5781 4 11.7499 4C10.9218 4 10.2499 4.67188 10.2499 5.5C10.2499 6.32812 10.9218 7 11.7499 7Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PersonMaleRegular;