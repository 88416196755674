import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PersonMaleLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9094 12.7563L14.9809 9.28438C14.5687 8.49375 13.7313 8 12.8219 8H12.1781C11.2687 8 10.4319 8.49375 9.99125 9.2875L8.06281 12.7563C7.92903 12.9975 8.01594 13.3016 8.25712 13.4359C8.49637 13.5688 8.80212 13.4843 8.93775 13.2416L10.8662 9.76972C11.1313 9.29688 11.6312 9 12.1781 9H12.8247C13.3697 9 13.8716 9.29541 14.135 9.77156L16.0634 13.2434C16.1531 13.4062 16.325 13.5 16.5 13.5C16.583 13.5 16.6653 13.48 16.7425 13.437C16.9844 13.3031 17.0719 12.9719 16.9094 12.7563ZM12.5 7.5C13.4647 7.5 14.2219 6.715 14.2219 5.75C14.2219 4.785 13.4656 4 12.5 4C11.5344 4 10.7781 4.785 10.7781 5.75C10.7781 6.715 11.5344 7.5 12.5 7.5ZM12.5 5C12.9131 5 13.2219 5.33656 13.2219 5.75C13.2219 6.16344 12.9125 6.5 12.5 6.5C12.0875 6.5 11.75 6.16344 11.75 5.75C11.75 5.33656 12.0875 5 12.5 5ZM13.9719 11.5C13.6955 11.5 13.5 11.7236 13.5 12V14.5H11.5V12C11.5 11.7236 11.2764 11.5 11.0281 11.5C10.7799 11.5 10.5 11.725 10.5 12V19.5C10.5 19.7764 10.7236 20 10.9719 20C11.2201 20 11.4437 19.7764 11.4437 19.5V15.5H13.4156V19.5C13.4156 19.7764 13.6392 20 13.8875 20C14.1357 20 14.3594 19.7764 14.3594 19.5V12C14.4719 11.725 14.275 11.5 13.9719 11.5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PersonMaleLight;