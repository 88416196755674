import { ATTEMPT_LOGIN_RESET_INTERVAL, FIRST_ACCESS_REQUERED_LOCAL_STORAGE_KEY, FORGOT_PASSWORD_REQUIRED_LOCAL_STORAGE_KEY } from "constants/LOGIN_ATTEMPTS";

interface ILoginAttemptsValue {
    document: string,
    loginAttempts: number,
    lastLoginAttempt: Date
}

const removeMask = (value: string) => {
    return value.replace(/\D/g, '');
};

const updateAttempts = (key: string, document: string) => {
    const currentTime = new Date().getTime();
    const storedAttempts = localStorage.getItem(key);
    let attempts = storedAttempts ? JSON.parse(storedAttempts) : [];

    let userAttempt = attempts.find((attempt: ILoginAttemptsValue) => attempt.document === document);

    if (userAttempt) {
        const lastAttemptTime = new Date(userAttempt.lastLoginAttempt).getTime();

        if (currentTime - lastAttemptTime < ATTEMPT_LOGIN_RESET_INTERVAL) {
            userAttempt.loginAttempts += 1;
        } else {
            userAttempt.loginAttempts = 1;
            userAttempt.lastLoginAttempt = new Date();
        }
    } else {
        userAttempt = {
            document,
            loginAttempts: 1,
            lastLoginAttempt: new Date()
        };
        attempts.push(userAttempt);
    }

    localStorage.setItem(key, JSON.stringify(attempts));

    return userAttempt;
};

export const notFoundUserLocalStorageData = (document: string, forgotPasswordIsRequired?: boolean, firstAccessIsRequired?: boolean) => {
    if (forgotPasswordIsRequired) return  updateAttempts(FORGOT_PASSWORD_REQUIRED_LOCAL_STORAGE_KEY, removeMask(document))
    if (firstAccessIsRequired) return updateAttempts(FIRST_ACCESS_REQUERED_LOCAL_STORAGE_KEY, removeMask(document))
    return null;
    
}

export const removeUserFromLocalStorage = (key: string, document: string) => {
    const storedAttempts = localStorage.getItem(key);
    if (storedAttempts) {
        const attempts = JSON.parse(storedAttempts);
        const updatedAttempts = attempts.filter((attempt: ILoginAttemptsValue) => attempt.document !== removeMask(document));
        localStorage.setItem(key, JSON.stringify(updatedAttempts));
    }
};
const clearOldEntries = (key: string, currentTime: number) => {
    const storedAttempts = localStorage.getItem(key);
    if (storedAttempts) {
        const attempts = JSON.parse(storedAttempts);
        const updatedAttempts = attempts.filter((attempt: ILoginAttemptsValue) => {
            const lastAttemptTime = new Date(attempt.lastLoginAttempt).getTime();
            return currentTime - lastAttemptTime < ATTEMPT_LOGIN_RESET_INTERVAL;
        });
        localStorage.setItem(key, JSON.stringify(updatedAttempts));
    }
};

export const clearOldLocalStorageEntries = () => {
    const currentTime = new Date().getTime();
    clearOldEntries(FORGOT_PASSWORD_REQUIRED_LOCAL_STORAGE_KEY, currentTime);
    clearOldEntries(FIRST_ACCESS_REQUERED_LOCAL_STORAGE_KEY, currentTime);
};

