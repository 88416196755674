import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const UploadLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.8893 8.84708L11.6136 5.6137V15.5001C11.6136 15.7765 11.8406 16.0001 12.1212 16.0001C12.4018 16.0001 12.6288 15.7765 12.6288 15.5001V5.6137L16.3531 8.87302C16.4483 8.95958 16.5688 9.0002 16.6894 9.0002C16.8292 9.0002 16.9689 8.94358 17.0691 8.83224C17.2555 8.62618 17.2357 8.30975 17.0265 8.12663L12.4583 4.1267C12.266 3.95777 11.9764 3.95777 11.7842 4.1267L7.216 8.12663C7.00675 8.30959 6.98708 8.62521 7.17235 8.83146C7.35761 9.0377 7.6831 9.05645 7.8893 8.84708ZM18.2121 14.0001H14.6591C14.3788 14.0001 14.1515 14.2239 14.1515 14.5001C14.1515 14.7762 14.3788 15.0001 14.6591 15.0001H18.2121C18.7727 15.0001 19.2273 15.4479 19.2273 16.0001V18C19.2273 18.5522 18.7727 19 18.2121 19H6.0303C5.46975 19 5.01515 18.5522 5.01515 18V16.0001C5.01515 15.4479 5.46975 15.0001 6.0303 15.0001H9.58333C9.8625 15.0001 10.0909 14.7751 10.0909 14.5001C10.0909 14.2251 9.8625 14.0001 9.58333 14.0001H6.0303C4.90888 14.0001 4 14.8954 4 16.0001V18C4 19.1047 4.90888 20 6.0303 20H18.2121C19.3335 20 20.2424 19.1047 20.2424 18V16.0001C20.2424 14.897 19.332 14.0001 18.2121 14.0001ZM17.9583 17.0001C17.9583 16.586 17.6173 16.2501 17.197 16.2501C16.7766 16.2501 16.4356 16.586 16.4356 17.0001C16.4356 17.4141 16.7766 17.75 17.197 17.75C17.6173 17.75 17.9583 17.4157 17.9583 17.0001Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default UploadLight;