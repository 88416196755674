import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const DocumentRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4143 6.91822L15.0821 4.58581C14.707 4.21072 14.1983 4 13.6679 4H8C6.89544 4 6 4.89544 6 6L6.00062 18C6.00062 19.1046 6.89603 20 8.00062 20H16C17.1 20 18 19.1 18 18V8.33247C18 7.80203 17.7893 7.29331 17.4143 6.91822ZM16.0001 18.5H8.00053C7.72441 18.5 7.50053 18.2762 7.50053 18L7.50003 6.00394C7.5 5.72778 7.72388 5.50391 8.00003 5.50391H13.0002V8C13.0002 8.55228 13.448 9 14.0002 9H16.5001V18C16.5001 18.2762 16.2762 18.5 16.0001 18.5Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default DocumentRegular;