import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const DocumentLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4143 7.91419L14.0858 4.58581C13.7108 4.21069 13.202 4 12.6716 4H8C6.89544 4 6 4.89544 6 6V18C6 19.1046 6.89544 20 8 20H16C17.1046 20 18 19.1046 18 18V9.32844C18 8.79797 17.7893 8.28931 17.4143 7.91419ZM16.7071 8.62122C16.815 8.72913 16.8862 8.86009 16.9351 9H13.5C13.2246 9 13 8.77588 13 8.5V5.06488C13.1399 5.11384 13.2708 5.18506 13.3787 5.29297L16.7071 8.62122ZM17 18C17 18.5514 16.5514 19 16 19H8C7.44859 19 7 18.5514 7 18V6C7 5.44859 7.44859 5 8 5H12V8.5C12 9.32716 12.6728 10 13.5 10H17V18Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default DocumentLight;