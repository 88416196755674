import React, { useMemo, useState } from 'react';
import { Route, Navigate, Routes as Container } from 'react-router-dom';
import shallow from 'zustand/shallow';
import useStore from 'hooks/useStore';
import publics from './publics';
import privates from './privates';
import ClientWrapper from 'components/ClientWrapper';
import { getHomeRoute } from 'utils/pathHandler';
import { privatePaths } from './paths';
import NewPassword from 'pages/public/NewPassword';
import { TUserRole } from 'types/user';
import { UpdatePasswordModal } from 'components/UpdatePasswordModal/UpdatePasswordModal';

const Routes: React.FC = () => {
  const { bearerToken, hasATempPassword, role } = useStore(state => 
    ({
      bearerToken: state.bearerToken,
      hasATempPassword: state.user.hasATempPassword,
      role: state.user.role
    }), shallow);
    const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false)
  const isLogged = useMemo(() => !!bearerToken.token, [bearerToken.token]);

  const PublicRoutes = publics.map(route => (
    <Route path={route.path} key={`public_${route.path}`} element={
      <React.Suspense>
          <route.Component/>
      </React.Suspense>
    }/>
  ));

  const PrivateRoutes = (role: TUserRole) => privates.filter(i => !i.role || i.role.includes(role)).map(route => (
    <Route path={route.path} key={`private_${route.path}`} element={
      <React.Suspense>
        <ClientWrapper setShowUpdatePasswordModal={setShowUpdatePasswordModal}>
          <route.Component/>
        </ClientWrapper>
      </React.Suspense>}/>
  ));

  return (
    <>
      <Container>
        {
          isLogged ? hasATempPassword? 
          (<>
            <Route
                  path={privatePaths.contracts.path}
                  element={<NewPassword/>}
              />
              <Route
                  path={privatePaths.users_clients.path}
                  element={<NewPassword/>}
              />
          </>) : PrivateRoutes(role) : PublicRoutes
        }
        <Route
          path="*"
          element={<Navigate to={getHomeRoute(role)} replace />}
        />
      </Container>
      {showUpdatePasswordModal && <UpdatePasswordModal showUpdatePasswordModal={showUpdatePasswordModal} setShowUpdatePasswordModal={setShowUpdatePasswordModal}/>}
    </>
  );
}

export default Routes;