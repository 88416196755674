import styled, { css } from "styled-components";

export const IconCon = styled.span<{ hasError?: boolean }>`
  height: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${({ theme, hasError }) => css`
    svg {
      color: ${hasError
        ? theme.brand.color.negative.pure
        : theme.brand.color.primary.dark} !important;
    }
  `}
`;
