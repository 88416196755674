import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ArrowLeftLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.2117 18.9374L4.28576 12.3998C4.18276 12.2867 4.13184 12.1433 4.13184 12C4.13184 11.8566 4.18276 11.7132 4.28576 11.6001L10.2117 5.06254C10.4316 4.81994 10.8066 4.80197 11.0485 5.0225C11.2927 5.24362 11.3078 5.62031 11.089 5.86231L6.06413 11.4056L19.5392 11.4056C19.8668 11.4056 20.1318 11.6715 20.1318 12C20.1318 12.3285 19.8668 12.5943 19.5392 12.5943L6.06413 12.5943L11.089 18.1376C11.3078 18.3796 11.2927 18.7563 11.0485 18.9774C10.8066 19.198 10.4316 19.18 10.2117 18.9374Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default ArrowLeftLight;