import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ArrowFromBracketSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.9998 16.8857H16.9999C17.5521 16.8857 17.9999 16.4422 17.9999 15.8952V7.97143C17.9999 7.4245 17.5521 6.98095 16.9999 6.98095H14.9998C14.4476 6.98095 13.9998 6.5374 13.9998 5.99048C13.9998 5.44355 14.4467 5 14.9998 5H16.9999C18.6568 5 20 6.33033 20 7.97143V15.8952C20 17.5363 18.6568 18.8667 16.9999 18.8667H14.9998C14.4476 18.8667 13.9998 18.4231 13.9998 17.8762C13.9998 17.3293 14.4467 16.8857 14.9998 16.8857ZM4.29325 11.2338L8.29338 7.2719C8.68433 6.88469 9.31716 6.88531 9.70749 7.2719C10.0981 7.65881 10.0981 8.28559 9.70749 8.6725L7.4121 10.9429H13.9998C14.553 10.9429 14.9998 11.3855 14.9998 11.9333C14.9998 12.4812 14.5526 12.9238 13.9998 12.9238H7.4121L9.7053 15.1951C10.0959 15.582 10.0959 16.2088 9.7053 16.5957C9.31466 16.9826 8.68183 16.9826 8.29119 16.5957L4.29106 12.6338C3.90261 12.246 3.90261 11.6207 4.29325 11.2338Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default ArrowFromBracketSolid;