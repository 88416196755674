import { useCallback, useState } from "react";
import { Button, toast, Typography, useTheme } from "@stardust-ds/react";
import Input from "components/Input";
import { useFormik } from "formik";
import useStore from "hooks/useStore";
import { shallow } from "zustand/shallow";
import { Logo } from "styles/global";
import { newPasswordSchema } from "validations/yup/publicSchemas";
import { AccessCard } from "components/AccessWrapper/styles";
import AccessWrapper from "components/AccessWrapper";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import PublicSuccessMessage from "components/PublicSuccessCard";

const NewPassWord: React.FC = () => {
  const theme = useTheme();
  const { token } = useParams();
  const {api, updatePassword, isLogged } = useStore(state => ({
    api: state.getApi(),
    updatePassword: state.updatePassword,
    isLogged: !!state.bearerToken.token,
  }), shallow);

  const [showMessage, setShowMessage] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validateOnChange: false,
    validationSchema: newPasswordSchema,
    onSubmit: async (data) => {
      try {
        if(isLogged){
          await updatePassword(data);
        }
        else {
          await api.post('/auth/new_password', data, {
            headers: {
              authorization: `Bearer ${token}`
            }
          });
        }
        setShowMessage(true);
      } catch(err) {
        if(err instanceof AxiosError){
          toast({
            type: "error",
            description: err?.response?.data?.message,
            position: "bottom-right",
          });
        }
      } finally {
        formik.setSubmitting(false);
      }
    }
  });
    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        formik.setFieldError(name, '');
        formik.handleChange(e);
    }, [formik]);

  return (
    <AccessWrapper>
      {
        showMessage ? 
        <PublicSuccessMessage
          title="Senha alterada com sucesso!"
          description="Utilize-a para fazer seu login"
          goToLogin={true}
        /> :
        <AccessCard>
            <Logo src={require('assets/images/logo_mega_3.webp')}></Logo>
            <Typography data-title fontSize='lg' mTop="md" color={theme.neutrals.gray7}>
                Crie uma nova senha
            </Typography>
            <Typography data-title fontSize='xs' mTop="nano" fontWeight="normal" mBottom='sm' color={theme.neutrals.gray7}>
                Digite abaixo
            </Typography>
            <Input
                name='password'
                placeholder='Nova senha'
                mBottom='xxs'
                maxWidth={'260'}
                isPass
                value={formik.values.password}
                error={formik.errors.password}
                onChange={handleInputChange}
                />
            <Input 
                name='confirmPassword'
                isPass
                placeholder='Repetir senha'
                maxWidth={'260'}
                value={formik.values.confirmPassword}
                error={formik.errors.confirmPassword}
                onChange={handleInputChange}
                />
            <Button onClick={() => formik.handleSubmit()} type='button' disabled={formik.isSubmitting} mBottom='xs' bgColor={theme.brand.color.primary.pure} isFullWidth mTop='sm'>
                Criar senha
            </Button>
        </AccessCard>
    }
    </AccessWrapper>
  );
}

export default NewPassWord;