import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ArrowCircleLeftLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path d="M11 8.8L7.8 12M7.8 12L11 15.2M7.8 12H14.2M19 12C19 16.4183 15.4183 20 11 20C6.58172 20 3 16.4183 3 12C3 7.58172 6.58172 4 11 4C15.4183 4 19 7.58172 19 12Z" stroke="#004982" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </IconWrapper>
  );
}

export default ArrowCircleLeftLight;