import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const BuildingRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.75 7.25C8.75 6.97375 8.97375 6.75 9.25 6.75H10.75C11.025 6.75 11.25 6.97375 11.25 7.25V8.75C11.25 9.025 11.025 9.25 10.75 9.25H9.25C8.97375 9.25 8.75 9.025 8.75 8.75V7.25ZM14.75 6.75C15.025 6.75 15.25 6.97375 15.25 7.25V8.75C15.25 9.025 15.025 9.25 14.75 9.25H13.25C12.975 9.25 12.75 9.025 12.75 8.75V7.25C12.75 6.97375 12.975 6.75 13.25 6.75H14.75ZM8.75 11.25C8.75 10.975 8.97375 10.75 9.25 10.75H10.75C11.025 10.75 11.25 10.975 11.25 11.25V12.75C11.25 13.025 11.025 13.25 10.75 13.25H9.25C8.97375 13.25 8.75 13.025 8.75 12.75V11.25ZM14.75 10.75C15.025 10.75 15.25 10.975 15.25 11.25V12.75C15.25 13.025 15.025 13.25 14.75 13.25H13.25C12.975 13.25 12.75 13.025 12.75 12.75V11.25C12.75 10.975 12.975 10.75 13.25 10.75H14.75ZM6 6C6 4.89531 6.89531 4 8 4H16C17.1031 4 18 4.89531 18 6V18C18 19.1031 17.1031 20 16 20H8C6.89531 20 6 19.1031 6 18V6ZM7.5 6V18C7.5 18.275 7.72375 18.5 8 18.5H10.5V16.5C10.5 15.6719 11.1719 15 12 15C12.8281 15 13.5 15.6719 13.5 16.5V18.5H16C16.275 18.5 16.5 18.275 16.5 18V6C16.5 5.72375 16.275 5.5 16 5.5H8C7.72375 5.5 7.5 5.72375 7.5 6Z" fill="currentColor"/>
        <path d="M8.75 7.25C8.75 6.97375 8.97375 6.75 9.25 6.75H10.75C11.025 6.75 11.25 6.97375 11.25 7.25V8.75C11.25 9.025 11.025 9.25 10.75 9.25H9.25C8.97375 9.25 8.75 9.025 8.75 8.75V7.25ZM14.75 6.75C15.025 6.75 15.25 6.97375 15.25 7.25V8.75C15.25 9.025 15.025 9.25 14.75 9.25H13.25C12.975 9.25 12.75 9.025 12.75 8.75V7.25C12.75 6.97375 12.975 6.75 13.25 6.75H14.75ZM8.75 11.25C8.75 10.975 8.97375 10.75 9.25 10.75H10.75C11.025 10.75 11.25 10.975 11.25 11.25V12.75C11.25 13.025 11.025 13.25 10.75 13.25H9.25C8.97375 13.25 8.75 13.025 8.75 12.75V11.25ZM14.75 10.75C15.025 10.75 15.25 10.975 15.25 11.25V12.75C15.25 13.025 15.025 13.25 14.75 13.25H13.25C12.975 13.25 12.75 13.025 12.75 12.75V11.25C12.75 10.975 12.975 10.75 13.25 10.75H14.75ZM6 6C6 4.89531 6.89531 4 8 4H16C17.1031 4 18 4.89531 18 6V18C18 19.1031 17.1031 20 16 20H8C6.89531 20 6 19.1031 6 18V6ZM7.5 6V18C7.5 18.275 7.72375 18.5 8 18.5H10.5V16.5C10.5 15.6719 11.1719 15 12 15C12.8281 15 13.5 15.6719 13.5 16.5V18.5H16C16.275 18.5 16.5 18.275 16.5 18V6C16.5 5.72375 16.275 5.5 16 5.5H8C7.72375 5.5 7.5 5.72375 7.5 6Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default BuildingRegular;