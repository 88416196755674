import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CarretSmallUpSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.32944 12.9482L11.1056 8.13822C11.3724 7.90148 11.6806 7.78499 11.9887 7.78499C12.2969 7.78499 12.6042 7.90242 12.8391 8.13728L17.6153 12.9473C17.9928 13.2902 18.0958 13.8088 17.9096 14.256C17.7234 14.7031 17.2846 15 16.7987 15L7.21253 15C6.72627 15 6.28735 14.7076 6.10097 14.2578C5.91458 13.8081 5.98372 13.2902 6.32944 12.9482Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CarretSmallUpSolid;