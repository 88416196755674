import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ArrowFromBracketLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.14688 11.5836L8.64688 7.12643C8.84219 6.93298 9.15875 6.93298 9.35375 7.12643C9.54875 7.31988 9.54906 7.63343 9.35375 7.82657L5.70625 11.4381H14.5C14.775 11.4381 15 11.661 15 11.9333C15 12.2057 14.7764 12.4286 14.5 12.4286H5.70625L9.35312 16.0407C9.54844 16.2342 9.54844 16.5477 9.35312 16.7409C9.15781 16.934 8.84125 16.9343 8.64625 16.7409L4.14625 12.2837C4.05 12.1871 4 12.0602 4 11.9333C4 11.8064 4.05 11.6795 4.14688 11.5836ZM14.5 17.8762H17.5C18.3272 17.8762 19 17.2107 19 16.3905V7.47619C19 6.65688 18.3272 5.99048 17.5 5.99048H14.5C14.225 5.99048 14 5.76886 14 5.49524C14 5.22162 14.225 5 14.5 5H17.5C18.8787 5 20 6.11057 20 7.47619V16.3905C20 17.7555 18.8787 18.8667 17.5 18.8667H14.5C14.225 18.8667 14 18.6438 14 18.3714C14 18.099 14.225 17.8762 14.5 17.8762Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default ArrowFromBracketLight;