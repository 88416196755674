import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const StarSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2731 4.5564L15.3178 8.69362L19.8916 9.35909C20.7124 9.47771 21.0412 10.4869 20.4463 11.0649L17.1366 14.2833L17.9193 18.8297C18.0602 19.6519 17.1933 20.2641 16.4664 19.8828L12.375 17.7361L8.28356 19.8828C7.55666 20.2671 6.68984 19.6519 6.83072 18.8297L7.61341 14.2833L4.30365 11.0649C3.70884 10.4869 4.03756 9.47771 4.85837 9.35909L9.43216 8.69362L11.4769 4.5564C11.8457 3.8099 12.9072 3.81918 13.2731 4.5564Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default StarSolid;