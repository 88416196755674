import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ChevronLeftLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.816 4.15224C15.9388 4.26442 16.0002 4.41785 16.0002 4.57188C16.0002 4.71085 15.9499 4.84978 15.8484 4.95971L9.34948 12.0005L15.8484 19.0413C16.0627 19.2734 16.0493 19.6356 15.816 19.8487C15.5839 20.063 15.2234 20.0485 15.0091 19.8169L8.15194 12.3883C7.9488 12.1685 7.9488 11.8325 8.15194 11.6127L15.0091 4.18406C15.2234 3.95245 15.5839 3.93795 15.816 4.15224Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default ChevronLeftLight;