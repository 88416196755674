import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ArrowFromBracketRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9978 18.4221C13.9978 18.8431 14.3323 19.1818 14.7481 19.1818H16.9989C18.6564 19.1818 20 17.8212 20 16.1429V8.03896C20 6.36057 18.6564 5 16.9989 5H14.7481C14.3323 5 13.9978 5.33998 13.9978 5.75974C13.9978 6.17918 14.3323 6.51948 14.7481 6.51948H16.9989C17.8242 6.51948 18.4995 7.20325 18.4995 8.03896V16.1429C18.4995 16.9786 17.8242 17.6623 16.9989 17.6623H14.7481C14.3323 17.6623 13.9978 18.0011 13.9978 18.4221ZM4.19734 11.5718L8.17067 7.26656C8.45496 6.95988 8.9297 6.9462 9.23075 7.23392C9.53261 7.52079 9.54711 8.00158 9.26298 8.30737L6.48255 11.3312H14.276C14.6637 11.3312 14.9982 11.6731 14.9982 12.0909C14.9982 12.5088 14.6621 12.8506 14.276 12.8506H6.51069L9.32172 15.8757C9.60601 16.1813 9.59135 16.662 9.28949 16.9491C9.11477 17.0862 8.93032 17.1558 8.77402 17.1558C8.57473 17.1558 8.37543 17.0757 8.22788 16.9165L4.25455 12.6113C3.92537 12.3188 3.92537 11.863 4.19734 11.5718Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default ArrowFromBracketRegular;