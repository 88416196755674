import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const StarLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8916 9.35906L15.3178 8.69359L13.2731 4.55641C13.0896 4.18659 12.7334 3.99994 12.375 4C12.0189 4 11.6607 4.18431 11.4768 4.55641L9.4321 8.69359L4.85838 9.35906C4.03757 9.47772 3.70882 10.4869 4.30369 11.0649L7.61338 14.2833L6.83072 18.8297C6.71938 19.4795 7.23747 20 7.81844 20C7.97257 20 8.13113 19.9634 8.28354 19.8828L12.375 17.7361L16.4664 19.8828C16.6184 19.9626 16.7766 19.9988 16.9303 19.9988C17.5118 19.9988 18.0307 19.4799 17.9193 18.8297L17.1366 14.2833L20.4463 11.0649C21.0412 10.4869 20.7124 9.47772 19.8916 9.35906ZM16.1062 14.1932L16.9311 18.9973L12.6073 16.7287C12.4618 16.6523 12.2881 16.6523 12.1426 16.7287L7.81619 18.9993L8.68825 13.9337L5.00235 10.3487L9.83607 9.64534C9.99872 9.62169 10.1394 9.51956 10.2123 9.37225L12.375 5L14.5377 9.37219C14.6106 9.51956 14.7512 9.62166 14.9139 9.64531L19.7492 10.348L16.2504 13.7502C16.1323 13.8651 16.0783 14.0308 16.1062 14.1932Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default StarLight;