import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CircleCheckLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6469 9.64687L11 13.2937L9.35313 11.6469C9.15781 11.4516 8.84125 11.4516 8.64625 11.6469C8.45125 11.8422 8.45094 12.1588 8.64625 12.3538L10.6462 14.3538C10.7437 14.45 10.8719 14.5 11 14.5C11.1281 14.5 11.2559 14.4512 11.3534 14.3535L15.3534 10.3535C15.5487 10.1582 15.5487 9.84163 15.3534 9.64663C15.1581 9.45162 14.8406 9.45 14.6469 9.64687ZM12 4C7.58125 4 4 7.58125 4 12C4 16.4187 7.58125 20 12 20C16.4187 20 20 16.4187 20 12C20 7.58125 16.4187 4 12 4ZM12 19C8.14062 19 5 15.8594 5 12C5 8.14062 8.14062 5 12 5C15.8594 5 19 8.14062 19 12C19 15.8594 15.8594 19 12 19Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CircleCheckLight;