import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const EyeSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9036 11.6382C18.3967 8.20905 15.4149 5.88892 12 5.88892C8.58506 5.88892 5.60244 8.21058 4.09636 11.6382C4.04339 11.7612 4 11.9734 4 12.1111C4 12.2494 4.04339 12.461 4.09636 12.5841C5.60331 16.0132 8.58506 18.3334 12 18.3334C15.4149 18.3334 18.3976 16.0116 19.9036 12.5841C19.9566 12.461 20 12.2488 20 12.1111C20 11.9729 19.9566 11.7612 19.9036 11.6382ZM16 12.1129C16 14.3199 14.2092 16.1111 12.0017 16.1111H12C9.79167 16.1111 8 14.319 8 12.1111C8 9.90325 9.79167 8.11114 12 8.11114C14.2083 8.11114 16 9.90325 16 12.1111V12.1129ZM12 9.44447H11.9939C11.93 9.44528 11.8452 9.45672 11.765 9.46569C11.9103 9.72328 12 10.0165 12 10.3334C12 11.3152 11.2041 12.1111 10.2222 12.1111C9.90419 12.1111 9.60997 12.0208 9.35167 11.8745C9.34342 11.9578 9.33333 12.0452 9.33333 12.1111C9.33333 13.5832 10.5279 14.7778 12 14.7778C13.4721 14.7778 14.6667 13.5832 14.6667 12.1111C14.6667 10.639 13.4721 9.44447 12 9.44447Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default EyeSolid;