import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const GaugeLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.33333 14C7.33333 14.3681 7.03472 14.6667 6.66667 14.6667C6.29861 14.6667 6 14.3681 6 14C6 13.6319 6.29861 13.3333 6.66667 13.3333C7.03472 13.3333 7.33333 13.6319 7.33333 14ZM12 9.33333C12.3681 9.33333 12.6667 9.03472 12.6667 8.66667C12.6667 8.29861 12.3681 8 12 8C11.6319 8 11.3333 8.29861 11.3333 8.66667C11.3333 9.03472 11.6319 9.33333 12 9.33333ZM8.22222 9.55556C7.85417 9.55556 7.55556 9.85417 7.55556 10.2222C7.55556 10.5903 7.85417 10.8889 8.22222 10.8889C8.59028 10.8889 8.88889 10.5903 8.88889 10.2222C8.88889 9.85417 8.59028 9.55556 8.22222 9.55556ZM16.0555 9.875C16.25 10.0312 16.2778 10.3125 16.125 10.5L13.4236 13.8333C13.6424 14.1319 13.7778 14.4931 13.7778 14.8889C13.7778 15.8715 12.9826 16.6667 12 16.6667C11.0174 16.6667 10.2222 15.8715 10.2222 14.8889C10.2222 13.9062 11.0174 13.1111 12 13.1111C12.2639 13.1111 12.5104 13.1701 12.7361 13.2743L15.4306 9.94444C15.5868 9.75 15.8646 9.72222 16.0555 9.875ZM12.8889 14.8889C12.8889 14.3993 12.4896 14 12 14C11.5104 14 11.1111 14.3993 11.1111 14.8889C11.1111 15.3785 11.5104 15.7778 12 15.7778C12.4896 15.7778 12.8889 15.3785 12.8889 14.8889ZM17.3333 13.3333C16.9653 13.3333 16.6667 13.6319 16.6667 14C16.6667 14.3681 16.9653 14.6667 17.3333 14.6667C17.7014 14.6667 18 14.3681 18 14C18 13.6319 17.7014 13.3333 17.3333 13.3333ZM20 14C20 15.4653 19.6042 16.8403 18.9167 18.0208C18.7604 18.2882 18.4618 18.4444 18.1528 18.4444H5.84722C5.53819 18.4444 5.23958 18.2882 5.08333 18.0208C4.39583 16.8403 4 15.4653 4 14C4 9.58333 7.58333 6 12 6C16.4167 6 20 9.58333 20 14ZM19.1111 14C19.1111 10.0799 15.9201 6.88889 12 6.88889C8.07986 6.88889 4.88889 10.0799 4.88889 14C4.88889 15.2569 5.22222 16.4757 5.85417 17.5556H18.1528C18.7778 16.4931 19.1111 15.2569 19.1111 14Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default GaugeLight;