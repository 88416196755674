import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const SortRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4744 13.0673H8.50309C7.17043 13.0673 6.49441 14.7928 7.44403 15.7975L10.943 19.5312C11.5252 20.1563 12.4789 20.1563 13.0648 19.5312L16.5388 15.7975C17.4756 14.7941 16.8377 13.0673 15.4744 13.0673ZM12.0005 18.4011L8.52655 14.6674H15.5025L12.0005 18.4011ZM8.49903 10.9037H15.4969C16.8296 10.9037 17.5056 9.17824 16.556 8.17347L13.0605 4.46879C12.4783 3.84374 11.5246 3.84374 10.9386 4.46879L7.46467 8.20248C6.49847 9.20691 7.16265 10.9037 8.49903 10.9037ZM12.0005 5.5989L15.4744 9.33258H8.49903L12.0005 5.5989Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default SortRegular;