import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CircleCheckSolid: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4C7.58125 4 4 7.58125 4 12C4 16.4187 7.58125 20 12 20C16.4187 20 20 16.4187 20 12C20 7.58125 16.4187 4 12 4ZM15.6187 10.6188L11.6188 14.6187C11.4469 14.7906 11.225 14.875 11 14.875C10.775 14.875 10.5519 14.79 10.3809 14.6191L8.38094 12.6191C8.04 12.2773 8.04 11.7226 8.38094 11.3807C8.72281 11.0388 9.2775 11.0388 9.61938 11.3807L11 12.7625L14.3813 9.38125C14.7231 9.03937 15.2778 9.03937 15.6197 9.38125C15.9594 9.72187 15.9594 10.2781 15.6187 10.6188Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CircleCheckSolid;