import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const PenSquareRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.491 5.53844L18.4652 4.51281C18.122 4.17097 17.675 4 17.2249 4C16.777 4 16.3291 4.17091 15.9871 4.51281L9.23557 11.2656C8.97301 11.5 8.8386 11.7969 8.76359 12.1187L8.01029 15.475C7.95403 15.7531 8.1697 16 8.41038 16C8.43901 16 8.46827 15.9971 8.49781 15.9912C8.49781 15.9912 10.8118 15.5003 11.8548 15.255C12.1705 15.1807 12.4528 15.0219 12.6819 14.7928L19.4647 8.01152C20.1756 7.32812 20.1724 6.22187 19.491 5.53844ZM18.4314 6.95L11.6486 13.7312C11.6142 13.7656 11.5799 13.7844 11.5392 13.7937C11.111 13.8946 10.4562 14.0393 9.84196 14.1731L10.2299 12.4481C10.2389 12.4031 10.2608 12.3594 10.2952 12.3281L17.0499 5.575C17.1092 5.51281 17.178 5.5 17.2249 5.5C17.2718 5.5 17.341 5.51269 17.4015 5.57319L18.4274 6.59912C18.5283 6.69812 18.5283 6.85219 18.4314 6.95ZM17.253 13C16.8389 13 16.5029 13.3359 16.5029 13.75V17.75C16.5029 18.1634 16.1659 18.5 15.7527 18.5H6.25051C5.83729 18.5 5.50034 18.1634 5.50034 17.75V8.25C5.50034 7.83656 5.83729 7.5 6.25051 7.5H10.7515C11.1657 7.5 11.5017 7.16406 11.5017 6.75C11.5017 6.33594 11.1673 6 10.7515 6L6.22238 5.99969C5.00992 5.99969 4 7.00906 4 8.22188V17.7219C4 18.9906 5.00992 20 6.22238 20H15.6964C16.937 20 17.9188 18.9906 17.9188 17.75L18.0032 13.75C18.0032 13.3375 17.6687 13 17.253 13Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default PenSquareRegular;