import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const DocumentCheckLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 8.5C13 8.22388 12.7762 8 12.5 8H5.5C5.22388 8 5 8.22388 5 8.5C5 8.77612 5.22388 9 5.5 9H12.5C12.7762 9 13 8.77612 13 8.5ZM5.5 14C5.22388 14 5 14.2239 5 14.5C5 14.7761 5.22388 15 5.5 15H9.5C9.77619 15 10 14.7761 10 14.5C10 14.2239 9.77619 14 9.5 14H5.5ZM11.75 19H5C4.44769 19 4 18.5522 4 18V6C4 5.44769 4.44769 5 5 5H13C13.5523 5 14 5.44769 14 6V9.75C14 10.0261 14.2239 10.25 14.5 10.25C14.7761 10.25 15 10.0261 15 9.75V6C15 4.89538 14.1046 4 13 4H5C3.89544 4 3 4.89538 3 6V18C3 19.1046 3.89544 20 5 20H11.75C12.0261 20 12.25 19.7761 12.25 19.5C12.25 19.2238 12.0261 19 11.75 19ZM12 11.5C12 11.2239 11.7762 11 11.5 11H5.5C5.22388 11 5 11.2239 5 11.5C5 11.7761 5.22388 12 5.5 12H11.5C11.7762 12 12 11.7761 12 11.5ZM16.5001 11C14.0145 11 12 13.0145 12 15.5C12 17.9855 14.0145 20 16.5001 20C18.9855 20 21 17.9855 21 15.5C21 13.0145 18.9855 11 16.5001 11ZM16.5001 19C14.5701 19 13 17.4299 13 15.5C13 13.5701 14.5701 12 16.5001 12C18.4299 12 20 13.5701 20 15.5C20 17.4299 18.4299 19 16.5001 19ZM17.8965 13.98L15.917 15.9595L15.1035 15.1465C14.9082 14.9512 14.5918 14.9512 14.3965 15.1465C14.2012 15.3418 14.2012 15.6582 14.3965 15.8535L15.5635 17.02C15.6611 17.1177 15.7891 17.1665 15.917 17.1665C16.0449 17.1665 16.1728 17.1177 16.2705 17.02L18.6035 14.687C18.7988 14.4917 18.7988 14.1753 18.6035 13.98C18.4082 13.7847 18.0918 13.7847 17.8965 13.98Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default DocumentCheckLight;