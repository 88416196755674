import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const BookLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 15.5V5.5C19 4.67156 18.3281 4 17.5 4H7.5C6.11937 4 5 5.11937 5 6.5V18C5 19.1047 5.89531 20 7 20H18.5C18.7764 20 19 19.7764 19 19.5C19 19.2236 18.775 19 18.5 19H18V16.9078C18.5813 16.7 19 16.15 19 15.5ZM6 6.5C6 5.67156 6.67156 5 7.5 5H8V16H7C6.63406 16 6.29531 16.1059 6 16.2781V6.5ZM17 19H7C6.44875 19 6 18.5513 6 18C6 17.4487 6.44875 17 7 17H17V19ZM17.5 16H9V5H17.5C17.775 5 18 5.22406 18 5.5V15.5C18 15.775 17.775 16 17.5 16ZM16 8H11C10.725 8 10.5 8.225 10.5 8.5C10.5 8.775 10.725 9 11 9H16C16.2764 9 16.5 8.77637 16.5 8.5C16.5 8.22363 16.275 8 16 8ZM16 11H11C10.725 11 10.5 11.225 10.5 11.5C10.5 11.775 10.725 12 11 12H16C16.2764 12 16.5 11.7764 16.5 11.5C16.5 11.2236 16.275 11 16 11Z" fill="currentColor"/>      </svg>
    </IconWrapper>
  );
}

export default BookLight;