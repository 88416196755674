import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const SearchLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8535 19.1465L15.4328 14.7259C16.4075 13.589 17 12.1149 17 10.5C17 6.91016 14.0899 4 10.5 4C6.91016 4 4 6.91016 4 10.5C4 14.0899 6.91016 17 10.5 17C12.1149 17 13.589 16.4075 14.7258 15.4329L19.1465 19.8535C19.2441 19.9512 19.3721 20 19.5 20C19.628 20 19.7559 19.9512 19.8535 19.8535C20.0488 19.6582 20.0488 19.3418 19.8535 19.1465ZM10.5 16C7.46728 16 5 13.5327 5 10.5C5 7.46728 7.46728 5 10.5 5C13.5327 5 16 7.46728 16 10.5C16 13.5327 13.5327 16 10.5 16Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default SearchLight;