import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const CarretSmallDownLight: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.7848 8H7.20247C6.1368 8 5.59702 9.29593 6.35279 10.0521L11.1271 14.8602C11.5966 15.3297 12.3573 15.3297 12.8268 14.8602L17.6011 10.0521C18.4226 9.29593 17.8892 8 16.7848 8ZM11.9767 14.0101L7.20284 9.20202H16.8153L11.9767 14.0101Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default CarretSmallDownLight;