import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const Help: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
    return (
        <IconWrapper {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.672 9.6C9.86008 9.06533 10.2313 8.61449 10.72 8.32731C11.2086 8.04013 11.7831 7.93515 12.3417 8.03097C12.9004 8.12679 13.4071 8.41722 13.7721 8.85082C14.1371 9.28443 14.3368 9.83322 14.336 10.4C14.336 12 11.936 12.8 11.936 12.8M12 16H12.008M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" stroke="#3B454F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </IconWrapper>
    );
}

export default Help;

