import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const LinkRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.9475 7.05235C18.2655 6.37085 17.376 6.02035 16.4805 6.00085C15.5325 5.98029 14.578 6.33085 13.878 7.05235L12.435 8.4736C12.7948 8.6592 13.1328 8.88685 13.4348 9.1706L14.7045 7.9006C15.1725 7.4331 15.787 7.1991 16.4018 7.1991C17.0163 7.1991 17.631 7.43308 18.099 7.9006C19.0345 8.8366 19.0345 10.3591 18.099 11.2956L15.2715 14.1231C14.364 15.0306 12.784 15.0299 11.8765 14.1231C11.423 13.6696 11.1735 13.0674 11.1735 12.4259C11.1735 11.9869 11.2985 11.5701 11.518 11.2026C11.2368 10.9434 10.8458 10.7921 10.4305 10.7921C10.4133 10.7921 10.3971 10.7963 10.38 10.7968C10.1225 11.2976 9.9725 11.8476 9.9725 12.4251C9.9725 13.3869 10.3472 14.2911 11.0272 14.9701C11.707 15.6501 12.6113 16.0249 13.5723 16.0249C14.5335 16.0249 15.438 15.6504 16.1173 14.9701L18.9447 12.1426C19.6275 11.4426 19.9775 10.5201 20 9.5976C20 8.6751 19.6275 7.75435 18.9475 7.05235ZM9.295 16.8776C8.827 17.3451 8.2125 17.5791 7.59775 17.5791C6.98325 17.5791 6.3685 17.3451 5.9005 16.8776C4.965 15.9416 4.965 14.4191 5.9005 13.4826L8.728 10.6551C9.6355 9.7476 11.2155 9.74835 12.123 10.6551C12.5765 11.1086 12.826 11.7109 12.826 12.3524C12.826 12.7914 12.701 13.2081 12.4815 13.5756C12.7628 13.8349 13.1538 13.9861 13.569 13.9861C13.5862 13.9861 13.6025 13.9819 13.6195 13.9814C13.8755 13.4809 14.026 12.9307 14.026 12.3524C14.026 11.3907 13.6513 10.4864 12.9713 9.80742C12.2915 9.12742 11.3873 8.75267 10.4263 8.75267C9.46502 8.75267 8.56053 9.12717 7.88128 9.80742L5.05275 12.6576C4.351 13.3576 4 14.2801 4 15.2026C4 16.1246 4.351 17.0464 5.05275 17.7476C5.7055 18.3999 6.5455 18.7516 7.40175 18.7974C8.38725 18.8501 9.393 18.4984 10.1443 17.7476L11.565 16.3276C11.2053 16.142 10.8672 15.9144 10.5652 15.6306L9.295 16.8776Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default LinkRegular;