import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const ChevronRightRegular: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.23448 4.57075L16.0486 11.6818C16.2141 11.8531 16.2963 12.0753 16.2963 12.2964C16.2963 12.5175 16.2141 12.7397 16.0486 12.911L9.23448 20.022C8.89552 20.3773 8.333 20.3889 7.97885 20.0486C7.62236 19.7107 7.61077 19.1459 7.95222 18.7929L14.1762 12.2964L7.95222 5.7999C7.61077 5.44804 7.62236 4.88208 7.97885 4.54412C8.333 4.20386 8.89552 4.21541 9.23448 4.57075Z" fill="currentColor"/>
      </svg>
    </IconWrapper>
  );
}

export default ChevronRightRegular;