export * from "./private";

export const publicPaths = {
    signin: {
      path: "/",
      title: "Login",
    },
    newPassword: {
      path: "/new_password/:token",
      title: "Nova Senha",
    },
    renewPassword: {
      path: "/renew_password",
      title: "Recuperação de Senha",
    },
    firstAccess: {
      path: "/first_access",
      title: "Primeiro Acesso",
    }
  };